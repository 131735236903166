import { Component } from "react";
import { Link } from "react-router-dom";
import ITask from '../types/Task/Task';
import ITaskEvent from '../types/Task/TaskEvent';
import { ButtonGroup, ToggleButton, Table, Alert, Modal } from 'react-bootstrap';
import { MsalContext } from "@azure/msal-react";
import warningImage from '../images/warning.png'
import TaskService from '../services/TaskService'
import { appInsights } from "../services/appInsights";
import { EventStatus } from "../enums/EventStatus";
import { StageLabel } from '../enums/Stage';
import LoadingWrapper from "../components/LoadingWrapper";
import TextField from "../components/TextField";
import SelectField from "../components/SelectField";
import ButtonType from "../components/ButtonType";
import DateField from "../components/DateField";
import EmployeeDetail from "../types/Employee/EmployeeDetail";

type Props = {
  userJobCode: string;
  preloadedTasks?: ITask[];
  updateTasks?: (e: any) => void;
};

export type TTaskList = ITask[]

type State = {
  tasks?: ITask[];
  events?: ITaskEvent[];
  message: string;
  radioValue: string;
  sortDueDate: string;
  filterDueDate: string;
  filterReportID: string;
  filterProject: string;
  filterDescription: string;
  filterStage: string;
  filterType: string;
  filterTier: string;
  filterAssignee: string;
  openTaskGroupModal: boolean;
  taskGroupModalMembers: EmployeeDetail[];
}

export default class TasksPage extends Component<Props, State> {
  static contextType = MsalContext;

  private timerID: number = 0;

  constructor(props: Props) {
    super(props);
    this.state = {
      tasks: this.props.preloadedTasks,
      message: "",
      radioValue: "All",
      sortDueDate: "asc",
      filterDueDate: "",
      filterReportID: "",
      filterProject: "",
      filterDescription: "",
      filterStage: "",
      filterType: "",
      filterTier: "",
      filterAssignee: "",
      openTaskGroupModal: false,
      taskGroupModalMembers: []
    }
  };

  async componentDidMount() {
    appInsights.trackPageView({name: "Task page"});

    this.getTasks();
    this.getUserEvents();    
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  async getTasks() {
    await TaskService.getTasks(this.context.accounts[0].username)
      .then((response) => {
        if (this.state.tasks !== response.data) {
          this.setState({
            tasks: response.data
          });
          
          if (this.props.updateTasks) {
            this.props.updateTasks(response.data)
          }
        }
      })
      .catch((e) => {
        this.setState({
          tasks: []
        });
      });
  }

  async getUserEvents() {
    await TaskService.getUserEvents(this.context.accounts[0].username)
      .then((response) => {
        if (this.state.events !== response.data) {
          this.setState({
            events: response.data
          });
        }

        clearInterval(this.timerID);

        // Check if there are any pending or in-progress events
        if (response.data.filter(x => x.status !== EventStatus.Fail).length > 0) {
          this.timerID = window.setInterval(() => this.getUserEvents(), 5000);
        } // Check if there are any failed events
        else if (response.data.filter(x => x.status === EventStatus.Fail).length > 0) {
          this.getTasks();
        }
      })
      .catch((e) => {
        this.setState({
          events: []
        });
      });
  }

  processDateCompare(date: string) {

    var dueDate = new Date(date);

    if(dueDate.getTime() < Date.now()) {
      return true;
    } else {
      return false;
    }
  }

  setChecked(value: string) {
    this.setState({
      radioValue: value,
    });
  }

  removeEvent(taskID: number) {
    var events = this.state.events;

    // Remove event from state
    if (events) {
      this.setState({
        events: events.filter(x => x.taskID !== taskID)
      });
    }
    
    // Call API to update dimiss flag
    TaskService.dismissUserEvents(taskID.toString(), this.context.accounts[0].username);
  }

  /**
   * Formats a date and returns it
   * @param date date to format
   * @returns formatted date
   */
  formatDate = (date: string) => {
    var newDate = new Date(date.split('T')[0].replace('-', '/'));
    return ("0" + (newDate.getMonth() + 1)).slice(-2) + '/' + ("0" + newDate.getDate()).slice(-2) + '/' + newDate.getFullYear();
  }

  /**
   * Sorts the comparison between two dates based on sortDueDate
   * @param date1 first date
   * @param date2 second date
   * @returns boolean
   */
  sortDateResult = (date1: string, date2: string) => {
    var d1 = new Date(date1);
    var d2 = new Date(date2);
    if (this.state.sortDueDate === "asc") {
      return d1 > d2 ? 1 : d1 < d2 ? -1 : 0;
    }
    else if (this.state.sortDueDate === "desc") {
      return d1 < d2 ? 1 : d1 > d2 ? -1 : 0;
    }
    else {
      return 0;
    }
  }

  /**
   * Sorts the Due Date column based on input
   * @param event sort input
   */
  sortDueDateColumn = (event: any) => {
    const currentSort = this.state.sortDueDate;

    // Swap the sorting direction
    var newSort = currentSort === "desc" ? "asc" : "desc";

    this.setState({
      sortDueDate: newSort
    });
  }

  /**
   * Filters the Due Date column based on input
   * @param event filter input
   */
  filterDueDateColumn = (event: any) => {
    this.setState({filterDueDate: event});
  }

  /**
   * Filters the Report ID column based on input
   * @param event filter input
   */
  filterReportIDColumn = (event: any) => {
    this.setState({filterReportID: event.target.value});
  }

  /**
   * Filters the Project column based on input
   * @param event filter input
   */
  filterProjectColumn = (event: any) => {
    this.setState({filterProject: event.target.value});
  }

  /**
   * Filters the Description column based on input
   * @param event filter input
   */
  filterDescriptionColumn = (event: any) => {
    this.setState({filterDescription: event.target.value});
  }

  /**
   * Filters the Stage column based on input
   * @param event filter input
   */
  filterStageColumn = (event: any) => {
    this.setState({filterStage: event.target.value});
  }

  /**
   * Filters the Type column based on input
   * @param event filter input
   */
   filterTypeColumn = (event: any) => {
    this.setState({filterType: event.target.value});
  }

  /**
   * Filters the Tier column based on input
   * @param event filter input
   */
  filterTierColumn = (event: any) => {
    this.setState({filterTier: event.target.value});
  }

  /**
   * Filters the Assignee column based on input
   * @param event filter input
   */
  filterAssigneeColumn = (event: any) => {
    this.setState({filterAssignee: event.target.value});
  }

  /**
   * Resets the filters
   */
  resetFilters = () => {
    this.setState({
      filterDueDate: "",
      filterReportID: "",
      filterProject: "",
      filterDescription: "",
      filterStage: "",
      filterType: "",
      filterTier: "",
      filterAssignee: "",
    });
  }

  /**
   * Called when opening the modal
   * @param task selected task
   */
  onOpenTaskGroupModal = (task: ITask) => {
    this.setState({
      taskGroupModalMembers: task.groupUsers ? task.groupUsers : [],
      openTaskGroupModal: true
    });
  }

  /**
   * Called when closing the modal
   */
  onCloseTaskGroupModal = () => {
    this.setState({
      openTaskGroupModal: false,
      taskGroupModalMembers: []
    });
  }

  /**
   * Searches for an employee (name) within a group
   * @param searchParam search string
   * @param group group to search
   * @returns boolean whether found or not
   */
  searchInGroup = (searchParam: string, group: EmployeeDetail[]) => {
    if (searchParam === "") {
      return false;
    }
    var matchFoundInGroup = group.find(x => x.employeeName.toLowerCase().includes(searchParam.toLowerCase()))
    return matchFoundInGroup ? true : false
  }

  render() {
    const {
      tasks,
      events,
      radioValue,
      sortDueDate,
      filterDueDate,
      filterReportID,
      filterProject,
      filterDescription,
      filterStage,
      filterType,
      filterTier,
      filterAssignee,
      openTaskGroupModal,
      taskGroupModalMembers
    } = this.state;

    const filterStageOptions = [
      {label: StageLabel[4], value: "4"},
      {label: StageLabel[5], value: "5"},
      {label: StageLabel[6], value: "6"},
      {label: StageLabel[7], value: "7"},
      {label: StageLabel[8], value: "8"},
      {label: StageLabel[9], value: "9"},
      {label: StageLabel[10], value: "10"},
      {label: StageLabel[11], value: "11"},
      {label: StageLabel[12], value: "12"}
    ];

    const filterTypeOptions = [
      {label: "Engineering", value: "Engineering"},
      {label: "Material", value: "Material"},
      {label: "Workmanship", value: "Workmanship"}
    ];

    const filterTierOptions = [
      {label: "1", value: "1"},
      {label: "2", value: "2"},
      {label: "3", value: "3"}
    ];

    const currentUserEmail = this.context.accounts[0].username;

    var displayedTasks: ITask[] = [];
    // Filter tasks based on Radio value
    if (tasks) {
      switch (radioValue) {
        case "All":
          // Display all tasks returned from the API
          displayedTasks = tasks;
          break;
        case "Mine":
          // Dislay tasks that the current user is assigned to
          displayedTasks = tasks.filter(x => x.assignedTo === currentUserEmail);
          break;
        case "Claimable":
          // Display tasks that the current user can claim (not claimed AND user is part of the allowed groups)
          displayedTasks = tasks.filter(x => x.assignedTo === "" && x.groupUsers?.find(x => x.employeeEmail === currentUserEmail));
          break;
        case "Viewable":
          // Display tasks that the current user can view (not assigned to current user AND (not claimed OR user not part of the allowed groups))
          displayedTasks = tasks.filter(x => x.assignedTo !== currentUserEmail && (x.assignedTo !== "" || !x.groupUsers?.find(x => x.employeeEmail === currentUserEmail)));
          break;
      }
    }

    const items = displayedTasks.length > 0 ? displayedTasks
      .slice()
      .filter(x => this.formatDate(x.dueDate).includes(filterDueDate ? this.formatDate(filterDueDate) : "") &&    // Filter on Due Date
          x.ncrID.toLowerCase().includes(filterReportID.toLowerCase()) &&                                         // Filter on Report ID
          `${x.projectNumber} - ${x.projectName}`.toLowerCase().includes(filterProject.toLowerCase()) &&          // Filter on Project
          x.description.toLowerCase().includes(filterDescription.toLowerCase()) &&                                // Filter on Description
          (x.stage.toString() === filterStage || filterStage === "") &&                                           // Filter on Stage
          (x.ncrType === filterType || filterType === "") &&                                                      // Filter on Type
          (x.tier.toString() === filterTier || filterTier === "") &&                                              // Filter on Tier
          (x.assignedToName.toLowerCase().includes(filterAssignee.toLowerCase())                                   // Filter on Assignee (Name)
            || (x.assignedToName === "" && this.searchInGroup(filterAssignee, x.groupUsers ? x.groupUsers : []))))// Filter on Assignee (Group)
      .sort((a, b) => this.sortDateResult(a.dueDate, b.dueDate))                                                  // Sort on Due Date
      .map((task) =>
      <tr key={task.id}>
          <td>{this.processDateCompare(task.dueDate) ? <img src={warningImage} width="20" height="20" alt=""/>  : ""}</td>
          <td className={this.processDateCompare(task.dueDate) ? "dueDateRed" : ""}>
            {new Date(task.dueDate).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit'})}
          </td>
          <td><Link to={{pathname: `/taskdetails/${task.id}`, state: this.props.userJobCode}}>{task.ncrID}</Link></td>
          <td>{task.projectNumber} - {task.projectName}</td>
          <td>{task.description}</td>
          <td>{StageLabel[task.stage]}</td>
          <td>{task.ncrType}</td>
          <td>{task.tier}</td>
          <td>{task.assignedToName ? task.assignedToName : <button onClick={() => this.onOpenTaskGroupModal(task)} className="ButtonLink">Group</button>}</td>
      </tr>
      ) : <tr>
            <td colSpan={100}>
              <b>No Tasks Found</b>
            </td>
          </tr>;

    const radios = [
      { name: 'All', value: 'All' },
      { name: 'Mine', value: 'Mine'},
      { name: 'Claimable', value: 'Claimable' },
      { name: 'Viewable', value: 'Viewable'}
    ];

    return (
      <div style={{width: "85%"}}>
        <LoadingWrapper showLoading={!tasks} loadingMessage="Getting Your NCR Tasks..." windSpinner windSpinnerScale={3}>
          <Modal show={openTaskGroupModal} onHide={this.onCloseTaskGroupModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Task Assigned To:</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <ul>
                  {taskGroupModalMembers.map((user, i) => 
                      <li key={i}>{user.employeeName}</li>
                  )}
                </ul>
              </div>
            </Modal.Body>
          </Modal>
          {events?.filter(x => x.status === EventStatus.Fail).map((event) => 
            <div>
            <Alert key={event.taskID} variant="danger" onClose={() => this.removeEvent(event.taskID)} dismissible>
              An error occurred while submitting <Link to={{pathname: `/taskdetails/${event.taskID}`} }>{event.ncrID}</Link>. Please submit the NCR again or contact IT Support.
            </Alert>
            </div>
          )}
          <h1 className="header mt-5 text-center">NCR Tasks</h1>
          <div className="d-flex justify-content-start align-items-center">
            <ButtonGroup className="mb-3">
              {radios.map((radio, idx) => (
                <ToggleButton
                  className={radioValue === radio.value ? "toggled-button-active" : "toggled-button"}
                  key={idx}
                  id={`radio-${idx}`}
                  type="radio"
                  variant="outline-primary"
                  name="radio"
                  value={radio.value ?? ""}
                  checked={radioValue === radio.value}
                  onChange={(e) => this.setChecked(e.currentTarget.value)}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
            {filterDueDate || filterReportID || filterProject || filterDescription || filterStage || filterType || filterTier || filterAssignee ?
              <div className="flex-grow-1 flex-wrap DivRight">
                <ButtonType className="mb-3 flex-grow-1 flex-wrap DivRight" dataName="filterReset" buttonText="Reset Filters" eventHandler={() => this.resetFilters()} />
              </div>
            : null}
          </div>
          <Table>
              <thead>
                <tr>
                  <th style={{width: 10}}></th>
                  <th className="ColumnSort" onClick={this.sortDueDateColumn} style={{width: 75}}>Due Date {sortDueDate === "" ? " ⮃" : sortDueDate === "asc" ? " ⭡" : " ⭣"}</th>
                  <th style={{width: 125}}>Report ID</th>
                  <th style={{width: 150}}>Project</th>
                  <th style={{width: 200}}>Description</th>
                  <th style={{width: 150}}>Stage</th>
                  <th style={{width: 100}}>Type</th>
                  <th style={{width: 75}}>Tier</th>
                  <th style={{width: 100}}>Assignee</th>
                </tr>
                <tr key="taskFilters">
                  <td></td>
                  <td><DateField dataName="filterDueDate" title="" value={filterDueDate} onchange={this.filterDueDateColumn} /></td>
                  <td><TextField dataName="filterReportID" title="" value={filterReportID} onchange={this.filterReportIDColumn} placeholder="Filter..." /></td>
                  <td><TextField dataName="filterProject" title="" value={filterProject} onchange={this.filterProjectColumn} placeholder="Filter..." /></td>
                  <td><TextField dataName="filterDescription" title="" value={filterDescription} onchange={this.filterDescriptionColumn} placeholder="Filter..." /></td>
                  <td><SelectField dataName="filterStage" title="" value={filterStage} onchange={this.filterStageColumn} options={filterStageOptions} allowSelectOption /></td>
                  <td><SelectField dataName="filterType" title="" value={filterType} onchange={this.filterTypeColumn} options={filterTypeOptions} allowSelectOption /></td>
                  <td><SelectField dataName="filterTier" title="" value={filterTier} onchange={this.filterTierColumn} options={filterTierOptions} allowSelectOption /></td>
                  <td><TextField dataName="filterAssignee" title="" value={filterAssignee} onchange={this.filterAssigneeColumn} placeholder="Filter..." /></td>
                </tr>
              </thead>
              <tbody>
                  {items}
              </tbody>
          </Table>
        </LoadingWrapper>
      </div>
    );
  }
}