import { AxiosResponse } from "axios";
import http from "../http-common";
import { DataSource } from "../types/Data/DataSource";
import DataSourceQueryList from "../types/Data/DataSourceQueryList";
import DataSourcesResponse from '../types/Data/DataSourcesResponse';
import IEnvironment from "../types/Data/Environment";
import FormEntry from "../types/FormEntry/FormEntry";

class DataService {
  async searchDataSourceData(dataSource: string, columnIndex: string, searchString: string) {

    // initialize to an "empty" Axios Response
    let response: AxiosResponse = { data: null, status: 0, statusText: '', headers: {}, config: {} };
    try {
      response = await http.get<string[]>(`/searchdatasourcedata?dsExternalID=${dataSource}&columnIndex=${columnIndex}&q=${searchString}`);
    } finally {
      // we are skipping the catch here on purpose
      //  we want to allow this pass even though it may be empty
      return response;
    }
  }

  /**
   * buildDataSourceQuery build the query needed for the request. 
   * @param DataSourceQueryList {query} is used to get the query from the query object.  
   * @returns The return will be the correct reduce query needed to send a GET HTTPS request. 
   */
  buildDataSourceQuery({query}: DataSourceQueryList){
    return query.reduce((finalString, current, index) => (finalString + '&query.Query[' + index + '].Column=' + current.column + current.values.reduce((f, c, i) => (f + '&query.Query[' + index + '].Values[' + i + ']=' + c), '')), '');
  }

  async searchDataSourceDataWithQuery(dataSource: string, columnIndex: number, query: DataSourceQueryList) {
    // initialize to an "empty" Axios Response
    let response: AxiosResponse = { data: null, status: 0, statusText: '', headers: {}, config: {} };
    try {
      response = await http.get<string[]>(`/searchDataSourceDataWithQuery?dsExternalID=${dataSource}&columnIndex=${columnIndex}${this.buildDataSourceQuery(query)}`);
    } finally {
      // we are skipping the catch here on purpose
      //  we want to allow this pass even though it may be empty
      return response;
    }
  }

  getUserDataSourceHeaders(userEmail: string, companyID: string, externalID?: string) {
    let url = `email=${userEmail}&companyID=${companyID}`
    if(externalID) {
      url += `&dsExternalID=${externalID}`
    }
    return http.get<DataSourcesResponse>(`/getUserDataSourceHeaders?${url}`);
  }

  getAllHeaders(companyID: string) {
    return http.get<DataSourcesResponse>(`/getAllSourceHeaders?companyID=${companyID}`);
  }

  async getDataSource(companyID: string, externalID: string) {
    let response: AxiosResponse = { data: null, status: 0, statusText: '', headers: {}, config: {} };
    try {
      response = await http.get<DataSource>(`/getDataSourceValues?companyID=${companyID}&dsExternalID=${externalID}`);
    } finally {
      // we are skipping the catch here on purpose
      //  we want to allow this pass even though it may be empty
      return response;
    }
  }

  setDataSource(dataSource: DataSource) {
    return http.post('/setDataSourceValues', dataSource, { headers: { 'Content-Type': 'application/json' }});
  }

  getFormEntry(companyID: string, formEntryID: string) {
    return http.get<FormEntry>(`/getFormEntry?companyID=${companyID}&formEntryID=${formEntryID}`);
  }

  clearDataSourceCache(companyID: string, externalID: string) {
    let url = `companyID=${companyID}&dsExternalID=${externalID}`;
    return http.post(`/clearDataSourceCache?${url}`);
  }

  getEnvironmentIds() {
      return http.get<IEnvironment[]>(`/getAllFormsOnFireProjectData`);
  }
}

const dataServiceInstance = new DataService();

export default dataServiceInstance;