/*
    This file is used to allow for consistant exports of the Data Source file
    data in the excel format
*/
export const HeaderStyle = {
    fill: {
        fgColor: { rgb: '00008B' }
    },
    font: {
        name: 'Tahoma',
        bold: true,
        color: {rgb: 'FFFFFF'},
        sz: 11,
    }
};

export const RowStyle = {
    font: {
        name: 'Tahoma',
        bold: false,
        color: {rgb: '000000'},
        sz: 11,
    }
};