import xlsx from "xlsx-js-style";

import { HeaderStyle, RowStyle } from "../../constants/DataSourceExcelStyling";
import DataSourceBackup from "../../types/DataSource/DataSourceBackup";
import Download from "../Icons/Download";

const blattnerBlue = '#1967d2';

type DataSourceBackupListProps = {
    backupList: DataSourceBackup[],
    isAdmin: boolean,
} & typeof defaultProps;

const defaultProps = {
    isAdmin: true,
}

const getDateStringFromEpoch = (epoch: number) => {
    const _date = new Date(epoch);
    let dateString = `${_date.getFullYear()}-`;
    dateString += `${_date.getMonth() + 1}-`;
    dateString += `${_date.getDate()}`;

    return dateString;
}

const getTimeStringFromEpoch = (epoch: number) => {
    const _date = new Date(epoch);
    let timeString = `${_date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
    })}`;

    return timeString;
}

const DataSourceBackupList = ({ backupList, isAdmin }: DataSourceBackupListProps) => {
    // setup so we can easily update the width of individual columns and have all the other
    //   widths be taken care of programatically
    const widths = [240, 120, 180, 340, 100]
    const totalWidth = widths.reduce((acc, cur) => acc + cur, 0);
    const styleDateString = { width: `${widths[0]}px` };
    const styleEnvironment = { width: `${widths[1]}px` };
    const styleExternalID = { width: `${widths[2]}px` };
    const styleEmployee = { width: `${widths[3]}px` };
    const styleExport = { width: `${widths[4]}px` };

    const downloadDataSource = (data: DataSourceBackup) => {
        // extract only the headers for the data source
        const headers: string[] = data.backupDataSource.split('\n').slice(0, 1)
            .flatMap((line) => line.split(',').map(word => word));

        // the way we are splitting the data source string makes the headers for the rows
        //  be in the zeroth row and causes the last row to be 'empty'
        //  so we are ignoring them using splice
        const rows: string[][] = data.backupDataSource.split('\n').slice(1, -1)
            .map((line) => line.split(',').map(word => word));

        let dataToExport:any[] = [];

        // trimming the front and the end of the of the values is neccessary so
        //  we don't have any left over spaces from doing the split on the csv string.
        // using trim() here would remove the inner spaces (if any) thus a
        //   regex replace was used to ensure we don't remove the inner spaces
        dataToExport.push(headers.map((header) => ({
            v: header.replace(/^\s+|\s+$/g, ''), // header value
            t: 's', // type of cell value, 's' is string
            s: HeaderStyle, // styling for the cell
        })));

        rows.forEach(row => {
            dataToExport.push(row.map((value) => ({
                v: value.replace(/^\s+|\s+$/g, ''), // value of the cell
                t: 's', // type of cell value, 's' is string
                s: RowStyle, // styling for the cell
            })));
        })

        const dateString = `${getDateStringFromEpoch(data.date)} ${getTimeStringFromEpoch(data.date)}`;

        const fileName = `${data.externalID}_${data.environmentID}_${dateString}.xlsx`;

        const ws = xlsx.utils.aoa_to_sheet(dataToExport);
        const wb = xlsx.utils.book_new();

        xlsx.utils.book_append_sheet(wb, ws, "Rows");
        xlsx.writeFile(wb, fileName);
    };

    return (
        <table className='data-source-history-table' style={{width: `${totalWidth}px`}}>
            <thead>
                <tr className='text-center'>
                    <th className='border-bottom' style={styleDateString}>Date</th>
                    { isAdmin && <th className='border-bottom' style={styleEnvironment}>Environment</th> }
                    { isAdmin && <th className='border-bottom' style={styleExternalID}>ExternalID</th> }
                    { isAdmin && <th className='border-bottom' style={styleEmployee}>Employee</th> }
                    <th className='border-bottom' style={styleExport}>Download</th>
                </tr>
            </thead>
            <tbody>
                {
                    backupList && backupList.map((backupDataSource, i) => {
                        const dateString = getDateStringFromEpoch(backupDataSource.date);
                        const timeString = getTimeStringFromEpoch(backupDataSource.date);
                        return(
                            <tr
                                key={`${backupDataSource.date}-{i}`}
                                className={`${(i === backupList.length - 1) ? '' : 'border-bottom'} text-center`}
                            >
                                <td style={styleDateString}>{dateString}&nbsp;&nbsp;{timeString}</td>
                                { isAdmin && <td style={styleEnvironment}>{backupDataSource.environmentID}</td>}
                                { isAdmin && <td style={styleExternalID}>{backupDataSource.externalID}</td>}
                                { isAdmin && <td style={styleEmployee}>{backupDataSource.employee}</td>}
                                <td style={styleExport}
                                    title='download'
                                    onClick={() => downloadDataSource(backupDataSource)}
                                >
                                    <Download color={blattnerBlue}/>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    );
}

DataSourceBackupList.defaultProps = defaultProps;

export default DataSourceBackupList;