export enum DataSourceHistoryType {
    Claim = 1,
    Save = 2,
    Export = 3,
    Import = 4,
    Delete = 5,
};

export const DataSourceHistoryTypeLabel: { [key in DataSourceHistoryType]: string } = {
    [DataSourceHistoryType.Claim]: 'Claim',
    [DataSourceHistoryType.Save]: 'Save',
    [DataSourceHistoryType.Export]: 'Export',
    [DataSourceHistoryType.Import]: 'Import',
    [DataSourceHistoryType.Delete]: 'Delete',
};