import { useEffect, useState } from "react";

import { BlattnerPortalPageLabel } from "../../enums/BlattnerPortalPage";
import { DataSourceHistoryTypeLabel } from "../../enums/DataSourceHistoryType";

import DataService from "../../services/DataService";
import EmployeeService from "../../services/EmployeeService";

import DataSourceHistoryDetail from "../../types/DataSource/DataSourceHistoryDetail";
import DataSourceQuery from "../../types/Data/DataSourceQuery";
import DataSourceQueryList from "../../types/Data/DataSourceQueryList";
import EmployeeDetail from "../../types/Employee/EmployeeDetail";

type EventLogDisplayProps = {
    eventLogs: DataSourceHistoryDetail[],
} & typeof defaultProps;

// this is blank intentionally
const defaultProps = {
};

const EventLogDisplay = ({ eventLogs }: EventLogDisplayProps) => {
    const widths = [200, 120, 160, 300, 250]
    const totalWidth = widths.reduce((acc, cur) => acc + cur, 0);
    const styleDateString = { width: `${widths[0]}px` };
    const styleEventType = { width: `${widths[1]}px` };
    const stylePage = { width: `${widths[2]}px` };
    const styleEmployee = { width: `${widths[3]}px` };
    const styleMessage = { width: `${widths[4]}px` };

    const [employeeDisplayList, setEmployeeDisplayList] = useState<EmployeeDetail[]>([]);

    useEffect(() => {
        // nothing to process if there is no list yet
        if (eventLogs.length === 0) {
            return;
        }

        const getUserInformation = async () => {
            const dsquery: DataSourceQuery = {
                column: 0,
                values: Array.from(new Set(eventLogs.map(e => e.employeeID))), // unique employee ids
            };
            const dsqueryList: DataSourceQueryList = {
                query: [dsquery],
            };
            try {
                // get email for all the users
                const { data } = await DataService.searchDataSourceDataWithQuery('BOOMI_EMPLOYEES_ENTERPRISE', 5, dsqueryList);
                // get the rest of the pertinent info for all the users
                const dataList = await Promise.all(data.map((d: string) => EmployeeService.getUserByEmail(d)));
                // extract the data from the users
                setEmployeeDisplayList(dataList.map(d => d.data));
            } catch (error) {
                console.log('error', error)
            }
        }

        getUserInformation();

    }, [eventLogs])

    const displayNoLogsFound = () => (
        <div className='text-center'>
            <h4>
                No Logs Found!
            </h4>
        </div>
    );

    const displayLogs = () => (
        <div
            className='ps-4'
        >

        <table
            className='data-source-event-log-table'
            style={{width: `${totalWidth}px`}}
        >
            <thead>
                <tr className='text-center'>
                    <th className='border-bottom' style={styleDateString}>Date</th>
                    <th className='border-bottom' style={styleEventType}>Event Type</th>
                    <th className='border-bottom' style={stylePage}>Page</th>
                    <th className='border-bottom' style={styleEmployee}>Employee</th>
                    <th className='border-bottom' style={styleMessage}>Message</th>
                </tr>
            </thead>
            <tbody>
                {
                    eventLogs.map(({
                        date,
                        employeeID,
                        eventType,
                        page,
                        message
                    }, i) => {
                        const _date = new Date(date);
                        let dateString = `${_date.getFullYear()}-`;
                        dateString += `${_date.getMonth() + 1}-`;
                        dateString += `${_date.getDate()}`;
                        let timeString = `${_date.toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: true
                        })}`
                        const pageString = BlattnerPortalPageLabel[page];
                        const eventTypeString = DataSourceHistoryTypeLabel[eventType];
                        return (
                            <tr
                                key={`${date}-{i}`}
                                className={`${(i === eventLogs.length - 1) ? '' : 'border-bottom'}`}
                            >
                                <td className='ps-3' style={styleDateString} title={date.toString()}>{dateString}&nbsp;&nbsp;{timeString}</td>
                                <td className='text-center' style={styleEventType} title={eventType.toString()}>{eventTypeString}</td>
                                <td className='text-center' style={stylePage}>{pageString}</td>
                                <td
                                    className='text-center' 
                                    style={styleEmployee}
                                    title = {employeeDisplayList.find(x => x.employeeID === employeeID)?.employeeEmail}
                                >
                                    {employeeDisplayList.find(x => x.employeeID === employeeID)?.employeeName}
                                </td>
                                <td className='text-center' style={styleMessage}>{message}</td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </table>
        </div>
    );

    return (eventLogs.length === 0 ? displayNoLogsFound() : displayLogs());
};

export default EventLogDisplay;