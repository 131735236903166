import { Component } from "react";
import DataService from "../services/DataService";
import Task from "../types/Task/Task";
import * as NCRDataConstants from "../constants/NCRDataConstants";
import TextField from "./TextField";
import TextArea from "./TextArea";
import GetScreenFieldValue from "../functions/GetScreenFieldValue";
import SelectField from "./SelectField";
import Select from 'react-select';
import { Spinner } from "react-bootstrap";
import Header from "./Header";

type Props = {
    task: Task
};

type State = {
    engineerPerformedBy: string,
    subcontractorList: string[],
    selectedSubcontractor: string,
    openMenu: boolean,
    subcontractorSearch: boolean,
    subcontractorsLoaded: boolean
};

export default class QualityExpediting extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            engineerPerformedBy: this.getScreenFieldValue(NCRDataConstants.ENGINEER_PERFORMED_BY),
            subcontractorList: [],
            selectedSubcontractor: this.getScreenFieldValue(NCRDataConstants.ENGINEER_EXTERNAL_FIRM),
            openMenu: false,
            subcontractorSearch: false,
            subcontractorsLoaded: false
        }
    }

    // Get subcontractor data
    //   add the retrieval of the "Enterprise" Data
    async searchSubcontractors(searchString: string) {
        try {
            // we want to use the legacy data if the JDESource field does not exists
            //   in the form otherwise use the enterprise data
            const targetDS = !!this.props.task.screen.screenFields.find(x => x.dataName === 'JDESource')?.value ? "BOOMI_SUBCONTRACTORS_ENTERPRISE" : "BOOMI_SUBCONTRACTORS";

            const subcontractorList = (await DataService.searchDataSourceData(targetDS, "1", searchString)).data;

            this.setState({
                subcontractorList,
                openMenu: true
            });
        } catch (e) {
            console.log(e);
        }
    }

    async componentDidMount() {
        await this.searchSubcontractors("");

        this.setState({
            subcontractorsLoaded: true
        });
    }

    handleAssignedToSearch = (query: string) => {
        if (query.length > 0) {
            this.searchSubcontractors(query);
        } 
        
        if (!this.state.subcontractorSearch) {
            this.setState({ selectedSubcontractor: query });
        }
    }

    handleAssignToChange = (event: any) => {
        this.handleAssignedToSearch(event.target.value);
        
        this.setState({ 
            selectedSubcontractor: event.target.value,
        });
    }

    changeMenu = (event: any) => {
        var value = "";
        var search = false

        if (event) {
            value = event.value;
            search = true;
        } 

        this.setState({ 
            openMenu: false,
            selectedSubcontractor: value,
            subcontractorSearch: search
        });
    }

    hideMenu = () => {
        this.setState({openMenu: false});
    }

    handleEngineerPeformedByChange = (event: any) => {
        const newValue = event.target.value;
        this.setState({ 
            engineerPerformedBy: newValue 
        });
    }

    getScreenFieldValue(dataName: string) {
        return GetScreenFieldValue(this.props.task.screen.screenFields, dataName);
    };

    getOwnerValue() {
        var owner = this.getScreenFieldValue(NCRDataConstants.ENGINEER_OWNER);
        if (owner === "") {
            owner = "Owner";
        }
        return owner;
    }

    render () {
        const { 
            engineerPerformedBy,
            subcontractorList,
            selectedSubcontractor,
            subcontractorsLoaded
        } = this.state;

        return (
            <div>
                <Header title={NCRDataConstants.ENGINEER_HEADER} headerColor="white" textColor="black" />
                <TextArea dataName={NCRDataConstants.ENGINEER_COMMENTS} title={NCRDataConstants.ENGINEER_COMMENTS_TITLE} value={this.getScreenFieldValue(NCRDataConstants.ENGINEER_COMMENTS)} required={true} />
                <SelectField dataName={NCRDataConstants.ENGINEER_PERFORMED_BY} title={NCRDataConstants.ENGINEER_PERFORMED_BY_TITLE} value={engineerPerformedBy} onchange={this.handleEngineerPeformedByChange} required={true}
                    options={[ { value: NCRDataConstants.ENGINEER_PERFORMED_BY_BLATTNER }, { value: NCRDataConstants.ENGINEER_PERFORMED_BY_EXTERNAL }, { value: this.getOwnerValue() }]} />
                {engineerPerformedBy === NCRDataConstants.ENGINEER_PERFORMED_BY_EXTERNAL ? 
                    !subcontractorsLoaded ? 
                        <div>
                            <Spinner animation="border" size="sm" />
                            <span> Loading...</span>
                            <input type="text" required={true} className="input-hidden" />
                        </div> :
                        <div>
                            <b><label htmlFor={NCRDataConstants.ENGINEER_EXTERNAL_FIRM}>{NCRDataConstants.ENGINEER_EXTERNAL_FIRM_TITLE} <label className="required">*</label></label></b><br />
                            {selectedSubcontractor.length !== 0 ? 
                                <div>
                                    <p className="HintText" />
                                    <Select
                                        placeholder=""
                                        name={NCRDataConstants.ENGINEER_EXTERNAL_FIRM}
                                        defaultInputValue={selectedSubcontractor}
                                        autoFocus={true}
                                        onInputChange={this.handleAssignedToSearch}
                                        onChange={this.changeMenu}
                                        onBlur={this.hideMenu}
                                        options={subcontractorList.map((subcontractor) => 
                                            ({ 
                                                "value" : subcontractor, 
                                                "label" : subcontractor
                                            }))}
                                        menuIsOpen={this.state.openMenu}
                                        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                                        isClearable={true}
                                        />
                                </div>
                                : <TextField dataName="AssignToPlaceholder" title="" value="" readonly={false} required={true} placeholder="Search..."
                                    onchange={this.handleAssignToChange} autoFocus={true} /> }
                        </div> : null }
            </div>
        )
    }
}
