/* <------------------------> */
//#region IMPORTS
/* <------------------------> */

import React, { Component, createRef } from "react";
import { appInsights } from '../services/appInsights';
import { Redirect } from 'react-router';
import { Alert, Card, Modal, Nav, Button } from 'react-bootstrap';
import TextField from '../components/TextField';
import NumberField from '../components/NumberField';
import DateField from '../components/DateField';
import MediaField from '../components/MediaField';
import SelectField from '../components/SelectField';
import ButtonType from '../components/ButtonType';
import { MsalContext } from "@azure/msal-react";
import { FieldType } from '../enums/FieldType';
import { Stage, StageLabel } from '../enums/Stage';
import TaskService from "../services/TaskService";
import ITask from '../types/Task/Task';
import IEmployeeDetail from '../types/Employee/EmployeeDetail';
import IScreenFieldUpdate from '../types/Screen/ScreenFieldUpdate';
import ITaskAssign from '../types/Task/TaskAssign'
import ITaskDelete from '../types/Task/TaskDelete'
import IFileUpload from '../types/FileUpload'
import QualityExpediting from "../components/QualityExpediting";
import TextArea from "../components/TextArea";
import FileField from "../components/FileField";
import SupplyChainReview from "../components/SupplyChainReview";
import ADSearchField from "../components/ADSearchField";
import TaskDiscussion from "../components/TaskDiscussion";
import ITaskComment from "../types/Task/TaskComment";
import ITaskHistory from "../types/Task/TaskHistory";
import EngineeringReview from "../components/EngineeringReview";
import ApproveRejectField from "../components/ApproveRejectField";
import * as NCRDataConstants from "../constants/NCRDataConstants";
import AcknowledgmentField from "../components/AcknowledgmentField";
import TaskHistory from "../components/TaskHistory";
import SectionWrapper from "../components/SectionWrapper";
import LoadingWrapper from "../components/LoadingWrapper";
import FormatNumber from "../functions/FormatNumber";
import DataService from "../services/DataService";
import SearchField from "../components/SearchField";
import { MultiSelectOption } from "../components/MultiSelectField";

/* <------------------------> */
//#endregion
/* <------------------------> */

/* <------------------------> */
//#region PROPS AND STATE
/* <------------------------> */

type Props = {};

type State = {
  selectOptionsReassign : {
      value: string,
      label: string
    }[],
    singleOptionsReassign: string,
    backToTaskPage: boolean,
    deleteReason: string,
    openDeleteModal: boolean,
    openReassignModal: boolean,
    loadingComplete: boolean,
    loadingMessage: string,
    message: string,
    taskId: any,
    userJobCode: string,
    fileUpload: IFileUpload,
    currentTask: ITask,
    qualityExpeditingStage: Stage,
    taskComments: ITaskComment[],
    taskCommentsLoaded: boolean,
    taskHistory: ITaskHistory[],
    tab: string,
    submitButton: string,
    approval?: string,
    rejectTo?: string,
    dcAcknowledgment: boolean,
    error: boolean,
    formError: boolean,
    workCodeOptions: MultiSelectOption[],
    defectFailureModesOptions: {label: string, value: string}[],
    subcontractorNameOptions: {label: string, value: string}[],
    supplierVendorNameOptions: {label: string, value: string}[]
  }

const overrideJobCodes: string[] = [
  "BQU351",   // Quality Systems Analyst
  "BQU400",   // Senior Quality Systems Analyst
  "BQU100"    // Quality Specialist
]

/* <------------------------> */
//#endregion
/* <------------------------> */

export default class TaskDetailsPage extends Component<Props, State> {
  static contextType = MsalContext;
  private saveButtonRef = createRef<HTMLButtonElement>();
  private formRef = createRef<HTMLFormElement>();

/* <------------------------> */
//#region STATE CONSTRUCTOR
/* <------------------------> */

  constructor(props: any) {
    super(props);
    this.state = {
      selectOptionsReassign: [
        {
          value: "",
          label: ""
        }
      ],
      singleOptionsReassign: "",
      backToTaskPage: false,
      deleteReason: "",
      openDeleteModal: false,
      openReassignModal: false,
      loadingComplete: false,
      loadingMessage: "",
      message: "",
      taskId: props.match.params.query,
      userJobCode: props.location.state,
      fileUpload: {
        files: [],
        dataName: ""
      },
      currentTask: {
        id: 0,
        ncrID: "",
        ncrType: "",
        projectNumber: "",
        projectName: "",
        location: "",
        description: "",
        dueDate: "",
        initialCostEsitmate: "",
        assignedTo: "",
        assignedToName: "",
        completed: false,
        groupUsers: [],
        stage: Stage.Unknown,
        tier: 0,
        screen: {
          name: "",
          screenFields: [
              {
                dataName: "",
                value: "",
                type: FieldType.None,
                title: "",
                hintText: "",
                buttonText: "",
                buttonColor: "",
                headerColor: "",
                textColor: "",
                time: false,
                readOnly: true,
                required: false,
                options: []
              }
            ] 
          }
        },
        qualityExpeditingStage: Stage.Unknown,
        taskComments: [],
        taskCommentsLoaded: false,
        taskHistory: [],
        tab: "NCR",
        submitButton: "",
        dcAcknowledgment: false,
        error: false,
        formError: false,
        workCodeOptions: [],
        defectFailureModesOptions: [],
        subcontractorNameOptions: [],
        supplierVendorNameOptions: []
      }
      this.handleChangeTextArea = this.handleChangeTextArea.bind(this);
      this.onChangeReassignOption = this.onChangeReassignOption.bind(this)
    };

/* <------------------------> */
//#endregion
/* <------------------------> */


/* <------------------------> */
//#region CREATING, REASSINGING, OR DELETING A TASK
/* <------------------------> */

  //function to claim task
  //userEmail is any because a string or array might get passed to it, depending on if it's reassign task or claiming.
  async claimTask(userEmail: string, id: number) {
    if (userEmail !== "") {
      this.setState({ 
        loadingComplete: false,
        loadingMessage: userEmail === this.context.accounts[0].username ? "Claiming NCR Task..." : "Reassigning NCR Task...",
        openReassignModal: false
      });

      var data : ITaskAssign = {
        id: id,
        user: this.context.accounts[0].username,
        assignedTo: userEmail
      }

      await TaskService.updateTask(data)
        .then((response) => {
          if(userEmail !== this.context.accounts[0].username) {
            appInsights.trackEvent({ name: "Reassign button clicked" });
            if (this.saveButtonRef.current) {
              this.saveButtonRef.current.click();
            }
            this.setState({ 
              loadingComplete: true,
              backToTaskPage: true
            });
          } else if (response.status === 200) {
            appInsights.trackEvent({ name: "Claim button clicked" });
            let task = {...this.state.currentTask};
            task.assignedTo = userEmail;
            this.setState({ 
              loadingComplete: true,
              currentTask: task,
              selectOptionsReassign: this.removeAssigneeFromList(this.state.selectOptionsReassign, userEmail)
            });
          } else {
            this.setState({ backToTaskPage: true });
          }
        }).catch((e) => {
            this.setState({ 
              loadingComplete: true,
              error: true
            });
        });
    }
  }

  //function to delete task
  async deleteTask(id: number, deleteReason: string) {
    if(deleteReason.length > 1) {
      this.setState({ 
        loadingComplete: false,
        loadingMessage: "Deleting NCR...",
        openDeleteModal: false
      });
      
      var data : ITaskDelete = {
        id: id,
        deleteReason: deleteReason,
        deletedByUserEmail: this.context.accounts[0].username
      }

      await TaskService.deleteTask(data)
        .then((response) => {
          if(response.status === 200) {
            appInsights.trackEvent({name: "Delete button clicked"});
            this.setState({ backToTaskPage: true });
          }
        }).catch((e) => {
            this.setState({ 
              loadingComplete: true,
              error: true
            });
        });
    }
  }

/* <------------------------> */
//#endregion
/* <------------------------> */


/* <------------------------> */
//#region GET TASK, COMMENTS, AND HISTORY DATA
/* <------------------------> */

  //function to get all screen data for the selected NCR.
  async getCurrentTask(id: number) {
    try {
      // Set loading message
      this.setState({
        loadingMessage: "Getting NCR Task Data..."
      });

      // Get the Task details
      const taskResponse = await TaskService.getTaskDetails(id.toString());

      const options = (taskResponse.data.groupUsers as IEmployeeDetail[]).map((d: any) => ({
        "value": d.employeeEmail,
        "label": d.employeeName,
      }))

      this.setState({
        currentTask: taskResponse.data,
        selectOptionsReassign: options
      });

      // Convert NonconformanceType of "Process" or "Product" to Workmanship
      this.handleOldNonconformanceType();

      // Updates to tier 3 based on Combined Total Cost
      this.handleTierChange();

      // Get select field options
      await this.getSelectFieldOptions();

    } catch (error) {
      this.setState({
        error: true
      });
    } finally {
      this.setState({
        loadingComplete: true
      });
    }
  }

  //function to get all comments for the discussion component/tab
  async getTaskComments(id: number) {
    await TaskService.getComments(id.toString())
        .then((response) => {  
          this.setState({
            taskComments: response.data,
            taskCommentsLoaded: true
          });
        }).catch((e) => {
          this.setState({ 
            taskCommentsLoaded: true
          });
      });
  }

  //function to get all comments for the history tab
  async getTaskHistory(id: number) {
    await TaskService.getHistory(id.toString())
        .then((response) => {  
          this.setState({
            taskHistory: response.data,
          });
        });
  }

  // Function to get options for specific select fields
  async getSelectFieldOptions() {
    // Set queries
    var workCodeQuery = {
      query: [{
        column: 1,
        values: [this.state.currentTask.projectNumber]
      }]
    };
    var subcontractorNameQuery = {
      query: [{
        column: 2,
        values: ["SUB"]
      }]
    };
    var supplierVendorNameQuery = {
      query: [{
        column: 2,
        values: ["C", "ENG", "EQP", "M", "MSC", "P", "R", "RNT", "ROY", "SAF", "SMP", "SMS", "STL", "SUB", "SVC", "T", "TIR"]
      }]
    };

    // Get Work Code options
    this.setState({
      loadingMessage: "Syncing Work Code Data from Blattner Mobile..."
    });

    // check if the form entry used Enterprise Data
    const isEnterprise = this.state.currentTask.screen.screenFields.find(x => x.dataName === 'JDESource')?.value === 'Enterprise';

    // Get work code data
    try {
      // we want to use the Enterprise data if the JDESource field exists
      //   in the form otherwise use the legacy data
      const targetDS = isEnterprise ? "BOOMI_WORKCODES_ENTERPRISE" : "BOOMI_WORKCODES";

      // Get work code data
      const workCodeData = (await DataService.searchDataSourceDataWithQuery(targetDS, 4, workCodeQuery)).data;

      if (workCodeData) {
          this.setState({
            workCodeOptions: workCodeData.sort().map((option: string) => ({
              label: option,
              // limit the value to only the work code at the beginning as this
              //  will match the data that is coming in from the form entry
              value: (option.match('(^\\d+)')?.[0] ?? ''),
            }))
          })
      }
    } catch {
      this.setState({
        workCodeOptions: [{label: "", value: ""}]
      });
    }

    // Get Defect Failure Modes options
    await DataService.searchDataSourceData("DATA_DEFINITIONS", "21", "")
      .then((response) => {
        if (response.data) {
          this.setState({
            defectFailureModesOptions: response.data.sort().map((option: MultiSelectOption) => {return {label: option, value: option}})
          })
        }
      })
      .catch((e) => {
        this.setState({
          defectFailureModesOptions: [{label: "", value: ""}]
        })
      });

    // Get Subcontractor Name options
    this.setState({
      loadingMessage: "Syncing Subcontractor Data from Blattner Mobile..."
    });

    // Get subcontractor name data
    try {
      // we want to use the Enterprise data if the JDESource field exists
      const targetDS = isEnterprise ? "BOOMI_SUBCONTRACTORS_ENTERPRISE" : "BOOMI_SUBCONTRACTORS";

      const searchResults = (await DataService.searchDataSourceDataWithQuery(targetDS, 1, subcontractorNameQuery)).data;

      //   in the form otherwise use the enterprise data
      this.setState({
        subcontractorNameOptions: searchResults.sort().map((option: string) => {return {label: option, value: option}})
      })
    } catch (e) {
        this.setState({
          subcontractorNameOptions: [{label: "", value: ""}]
        })
    }

    // Get Supplier/Vendor Name options
    this.setState({
      loadingMessage: "Syncing Supplier/Vendor Data from Blattner Mobile..."
    });
    // Get supplier/vendor name data
    try {
      // we want to use the enterprise data if the JDESource field exists
      //   in the form otherwise use the legacy data
      const targetDS = isEnterprise ? "BOOMI_SUBCONTRACTORS_ENTERPRISE" : "BOOMI_SUBCONTRACTORS";

      const searchResults = (await DataService.searchDataSourceDataWithQuery(targetDS, 1, supplierVendorNameQuery)).data;

      this.setState({
        supplierVendorNameOptions: searchResults.sort().map((option: string) => {return {label: option, value: option}})
      })
    } catch (e) {
        this.setState({
          supplierVendorNameOptions: [{label: "", value: ""}]
        })
    }
  }

/* <------------------------> */
//#endregion
/* <------------------------> */


/* <------------------------> */
//#region SAVE, SUBMIT, CHECKIFCLAIMED FUNCTIONS
/* <------------------------> */

  //function to save data to the database
  async saveTask(data: FormData) {
    await TaskService.saveTask(data)
        .then((response) => {
          if(response.status === 200) {
            // Update state to go back to tasks listing page
            this.setState({ 
              backToTaskPage: true 
            });
          }
        }).catch((e) => {
            this.setState({ 
              loadingComplete: true,
              error: true
            });
        });
  }

  //function to complete the task, save data to database, and create task for next step if there is any.
  async submitTask(data: FormData) {
    await TaskService.submitTask(data)
        .then((response) => {
          if(response.status === 200) {
            // Call API to run task events
            TaskService.runTaskEvents(this.state.currentTask.id.toString());

            // Update state to go back to tasks listing page
            this.setState({ 
              backToTaskPage: true
            });
          }
        }).catch((e) => {
            this.setState({ 
              loadingComplete: true,
              error: true
            });
        });
  }
  
  //checks if task is claim. If claimed, shows the reassign, delete, and save button. If not claimed, shows the claim task button.
  checkIfTaskIsClaimed() {
    var currentTask = this.state.currentTask;
    var claimed = currentTask.assignedTo;
    var userEmail = this.context.accounts[0].username;

    if (!claimed && this.state.selectOptionsReassign.some(x => x.value.toLowerCase() === userEmail.toLowerCase())) {
      return (
        /* Display the "Claim Task" button for unclaimed tasks if the user is in the appropriate group(s) */
        <div className="btn-group">
          <div id="RightAlignedFloatDiv">
            <ButtonType dataName="claimTask" color="blue" buttonText="Claim Task" eventHandler={() => {this.claimTask(userEmail, currentTask.id)}} />
          </div>
        </div>
      )
    } else if (!claimed && !this.state.selectOptionsReassign.some(x => x.value.toLowerCase() === userEmail.toLowerCase()) && overrideJobCodes.includes(this.state.userJobCode)) {
      return (
        /* Display the "Override Claim" button for unclaimed tasks if the user is NOT in the appropriate group(s) but is a role that can override claims */
        <div id="RightAlignedFloatDiv">
          <ButtonType dataName="overrideClaim" color="blue" buttonText="Override Claim" eventHandler={() => {this.claimTask(userEmail, currentTask.id)}} />
        </div>
      )
    } else if (claimed && !(claimed.toLowerCase() === userEmail.toLowerCase()) && overrideJobCodes.includes(this.state.userJobCode)) {
      return (
        /* Display the "Override Claim" button for claimed tasks if not claimed by the user and the user is a role that can override claims */
        <div className="btn-group">
          <div id="RightAlignedFloatDiv">
          <ButtonType dataName="overrideClaim" color="blue" buttonText="Override Claim" eventHandler={() => {this.claimTask(userEmail, currentTask.id)}} />
          </div>
        </div>
      )
    } else if (claimed.toLowerCase() === userEmail.toLowerCase()) {
      return (
        /* Display the "Reassign", "Delete", and "Save & Close" buttons if the user claimed the task */
        <div className="btn-group">
          <ButtonType dataName="reassignTask" color="blue" buttonText="Reassign" eventHandler={this.onOpenReassignModal} />
          <ButtonType dataName="deleteTask" color="red" buttonText="Delete" eventHandler={this.onOpenDeleteModal} />
          <div id="RightAlignedFloatDiv">
            <ButtonType dataName="Save" color="blue" buttonText="Save & Close" type="submit" eventHandler={this.setSubmitButton} ref={this.saveButtonRef} readOnly={this.state.formError} />
          </div>
        </div>
      )
    }
  }

  //Determines if it was the save button that was clicked, or the submit button. 
  setSubmitButton = (event: any) => {
    let button = event.target.name;
    this.setState({ submitButton: button});

    if (this.formRef.current) {
      if (button === "Submit") {
        appInsights.trackEvent({ name: "Submit button clicked" });
        this.formRef.current.className += " was-validated";
        this.formRef.current.noValidate = false;
      } else {
        if (button === "Save") {
            appInsights.trackEvent({ name: "Save button clicked" });
        }
        this.formRef.current.noValidate = true;
      }
    }
  }

  //Saving all data to state. Check if save or submit and call the appropiate function. 
  submitHandler = (event: any) => {
    event.preventDefault();
    
    // Check if save button or submit button
    var submitButton = this.state.submitButton;
    var save = submitButton === "Save";
    var tabChange = submitButton === "";

    const formData = new FormData(event.target);

    if (tabChange) {
      var task = this.state.currentTask;

      formData.forEach((value: FormDataEntryValue, key: string) => {
        if (!(value instanceof File)) {
          if (task.screen.screenFields.filter(f => f.dataName === key).length > 0) {
            var index = task.screen.screenFields.findIndex(f => f.dataName === key);
            task.screen.screenFields[index].value = value;
          } else {
            task.screen.screenFields.push({
              dataName: key,
              value: value,
              type: FieldType.None,
              title: "",
              hintText: "",
              buttonText: "",
              buttonColor: "",
              headerColor: "",
              textColor: "",
              time: false,
              readOnly: false,
              required: false,
              options: []
            });
          }
        }
      });

      this.setState({
        currentTask: task
      });
    } else if(save || event.target.checkValidity()) {
      let screenFields: IScreenFieldUpdate[] = [];
      /*
          The following two were created to be able to capture multiple entries
            for the closure status
      */
      let closureStatusValues: string[] = [];
      let newFormData = new FormData();

      formData.forEach((value: FormDataEntryValue, key: string) => {
        // catch the closure status and store the values in an array
        if (key === 'ClosureStatus') {
          closureStatusValues.push(value.toString())
        } else {
          newFormData.append(key, value);
        }
      })

      if(closureStatusValues.length > 0) {
        newFormData.append('ClosureStatus', closureStatusValues.join(', '));
      }

      // From this point on in this "branch" of the code use the 'newFormData'
      newFormData.forEach((value: FormDataEntryValue, key: string) => {
        if (!(value instanceof File)) {
          screenFields.push({dataName: key, value: value.toString()});
        }
      });

      // Add WorkType to the screenFields to ensure it's updated on the backend (normally isn't included since it doesn't display in UI)
      screenFields.push({dataName: "WorkType", value: this.getValue("WorkType")});

      this.setState({
        loadingComplete: false,
        loadingMessage: save ? "Saving NCR Task..." : "Submitting NCR Task..."
      });
  
      const data = new FormData();
      data.append("id", this.state.currentTask.id.toString())

      screenFields.forEach((field, i) => {
        data.append("screenFields[" + i + "].DataName", field.dataName);
        data.append("screenFields[" + i + "].Value", field.value);
      })      

      const files = this.state.fileUpload.files;
      if (files.length > 0) {
        files.forEach(file => {
          data.append("Files", file);
        })

        data.append("FileDataName", this.state.fileUpload.dataName);

        if (this.state.fileUpload.tableDataName) {
          data.append("TableDataName",  this.state.fileUpload.tableDataName);
        }

        if (this.state.fileUpload.filenameDataName) {
          data.append("FilenameDataName",  this.state.fileUpload.filenameDataName);
        }
      }

      if (save) {
        this.saveTask(data);
      } else {
        this.submitTask(data);
      }
    }
  };

/* <------------------------> */
//#endregion
/* <------------------------> */


/* <------------------------> */
//#region MODAL FUNCTIONS
/* <------------------------> */

  //function to set openDeleteModal variable to true when delete button clicked. 
  onOpenDeleteModal = () => {
    this.setState({ openDeleteModal: true });
  };

  //function to set openDeleteModal variable to false when close or red X button clicked. 
  onCloseDeleteModal = () => {
    this.setState({ 
      openDeleteModal: false,
      deleteReason: ""
    });
  };

  //function to set openReassignModal variable to true when reassign button clicked.
  onOpenReassignModal = () => {
    this.setState({ openReassignModal: true });
  };

  //function to set openReassigneModal variable to false when close or red X button clicked.
  onCloseReassignModal = () => {
    this.setState({ 
      openReassignModal: false,
      singleOptionsReassign: "",
    });
  };

  //TODO: is this named weird. I can't tell what it is doing. 
  modalTaskHandler = (event: any) => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

/* <------------------------> */
//#endregion
/* <------------------------> */


/* <------------------------> */
//#region HANDLERS
/* <------------------------> */

  //function to update the currentTask state so that the choices field updates when a new value is selected
  choicesValueChangeHandler = (dataName: string, event: any) => {
    this.setValue(dataName, event.target.value);

    // If "Type of Nonconformance" has changed
    if (dataName === "NonconformanceType") {

      // Update the "currentTask" ncrType value
      this.handleTypeChange();

      // Reset the "Responsible Party" and "Search By" values on "Type of Nonconformance" change
      this.setValue("ResponsibleParty", "");
      this.setValue("SearchBy", "");
    }
  }

  searchFieldValueChangeHandler = (dataName: string, option: any) => {
    this.setValue(dataName, option);

    // Change WorkType value if WorkCode is changing
    if (dataName === "WorkCode") {
      this.setWorkType(option);
    }
  }

  //function to setState when Delete Reason text area value changes. 
  handleChangeTextArea(event: any) {
    this.setState({ deleteReason: event.target.value });
  }

  //function to add file to list. Passed to child component to change parent state
  addFileHandler = (fileUpload: IFileUpload) => {
    this.setState(prevState => ({ fileUpload: {
      files: [...prevState.fileUpload.files, ...fileUpload.files],
      dataName: fileUpload.dataName,
      tableDataName: fileUpload.tableDataName,
      filenameDataName: fileUpload.filenameDataName
      }}));
  };

  //function to remove file from list. Passed to child component to change parent state
  removeFileHandler = (file: File) => {
    const newFiles = this.state.fileUpload.files;
    const index = newFiles.indexOf(file, 0);
    newFiles.splice(index, 1);
    this.setState({ fileUpload: {
        files: newFiles,
        dataName: this.state.fileUpload.dataName,
        tableDataName: this.state.fileUpload.tableDataName,
        filenameDataName: this.state.fileUpload.filenameDataName
        }});
  };

  //function to set tab state.
  handleTabChange = (selectedKey: any) => {
    // Get current tab
    var currentTab = this.state.tab;

    // Set submitButton to blank for Tab change
    this.setState({ submitButton: "" }, () => {
      if (currentTab === "NCR" && this.saveButtonRef.current) {
        // Save state of all fields if on NCR and moving to a new tab. This persists the values when coming back to NCR.
        this.saveButtonRef.current.click();
      }

      this.setState({ tab: selectedKey });
    });
  }

    //function to add new comments?
  handleNewComment = (comments: ITaskComment[]) => {
    this.setState({ 
      taskComments: comments 
    });
  }

  //function to set state for reassign user selected
  onChangeReassignOption(event: any) {
      this.setState({ singleOptionsReassign: event ? event.target.value : "" });
  }

  //function to remove assignee from list when task is claimed.
  removeAssigneeFromList(array: any, value: string) {
    var idx = array.findIndex((x: any) => x.value === value);
    if (idx !== -1) {
        array.splice(idx, 1);
    }
    return array;
  }

  //function to set singleOptionReassing on the parent from the AD Search field. 
  onADSearchChange = (value: string) => {
    this.setState({ singleOptionsReassign: value });
  }

  // function to track qualityExpeditingStage child variable at the parent level
  stageChange = (stage: Stage) => {
    this.setState({
      qualityExpeditingStage: stage
    });
  }

  // function to track approval child variable at the parent level
  approvalChange = (value: string) => {
    this.setState({
      approval: value
    });
  }
  
  // function to track reject to child variable at the parent level
  rejectToChange = (value: string) => {
    this.setState({
      rejectTo: value
    });
  }

  // function to track dcAcknowledgment child variable at the parent level
  dcAcknowledgmentChange = (value: string) => {
    if (value) {
      this.setState({
        dcAcknowledgment: true
      });
    }
  }

  handleItemizedCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Set updated cost value
    const name = event.target.name;
    const value = parseFloat(event.target.value) || 0;
    const roundedValue = FormatNumber(value, 2);
    this.setValue(name, roundedValue)

    // Get itemized costs
    var itemizedBlattnerCosts = [
      parseFloat(this.getValue(NCRDataConstants.INVESTIGATION_BLATTNER_COST)) || 0,
      parseFloat(this.getValue(NCRDataConstants.WORK_BLATTNER_COST)) || 0,
      parseFloat(this.getValue(NCRDataConstants.ADDITIONAL_BLATTNER_COST)) || 0,
      parseFloat(this.getValue(NCRDataConstants.REMOVAL_BLATTNER_COST)) || 0,
      parseFloat(this.getValue(NCRDataConstants.REINSTALLATION_BLATTNER_COST)) || 0,
      parseFloat(this.getValue(NCRDataConstants.OTHER_BLATTNER_COST)) || 0
    ]
    var itemizedNonBlattnerCosts = [
      parseFloat(this.getValue(NCRDataConstants.INVESTIGATION_NON_BLATTNER_COST)) || 0,
      parseFloat(this.getValue(NCRDataConstants.WORK_NON_BLATTNER_COST)) || 0,
      parseFloat(this.getValue(NCRDataConstants.ADDITIONAL_NON_BLATTNER_COST)) || 0,
      parseFloat(this.getValue(NCRDataConstants.REMOVAL_NON_BLATTNER_COST)) || 0,
      parseFloat(this.getValue(NCRDataConstants.REINSTALLATION_NON_BLATTNER_COST)) || 0,
      parseFloat(this.getValue(NCRDataConstants.OTHER_NON_BLATTNER_COST)) || 0
    ]

    // Get sum of itemized costs
    var totalBlattnerCost = itemizedBlattnerCosts.reduce((partialSum, nextNum) => partialSum + nextNum);
    var totalNonBlattnerCost = itemizedNonBlattnerCosts.reduce((partialSum, nextNum) => partialSum + nextNum);
    var totalCombinedCost = totalBlattnerCost + totalNonBlattnerCost;

    // Set Total Cost values
    this.setValue(NCRDataConstants.BLATTNER_TOTAL_COST, FormatNumber(totalBlattnerCost, 2));
    this.setValue(NCRDataConstants.NON_BLATTNER_TOTAL_COST, FormatNumber(totalNonBlattnerCost, 2));
    this.setValue(NCRDataConstants.COMBINED_TOTAL_COST, FormatNumber(totalCombinedCost, 2));
  }

  handleTotalCostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Set updated cost value
    const name = event.target.name;
    const value = parseFloat(event.target.value) || 0;
    const roundedValue = FormatNumber(value, 2);
    this.setValue(name, roundedValue)

    var totalBlattnerCost = parseFloat(this.getValue(NCRDataConstants.BLATTNER_TOTAL_COST));
    var totalNonBlattnerCost = parseFloat(this.getValue(NCRDataConstants.NON_BLATTNER_TOTAL_COST));
    var totalCombinedCost = totalBlattnerCost + totalNonBlattnerCost;

    // Set Total Cost values
    this.setValue(NCRDataConstants.BLATTNER_TOTAL_COST, FormatNumber(totalBlattnerCost, 2));
    this.setValue(NCRDataConstants.NON_BLATTNER_TOTAL_COST, FormatNumber(totalNonBlattnerCost, 2));
    this.setValue(NCRDataConstants.COMBINED_TOTAL_COST, FormatNumber(totalCombinedCost, 2));

    this.handleTierChange();
  }

  handleTierChange() {
    var combinedTotalCost = parseFloat(this.getValue(NCRDataConstants.COMBINED_TOTAL_COST));

    // Set tier 3 if applicable
    if (combinedTotalCost >= 25000) {
      var newTask = this.state.currentTask;
      newTask.tier = 3;
      this.setState({
        currentTask: newTask
      })
    }
  }

  handleTypeChange() {
    var newType = this.getValue("NonconformanceType")

    // Update type
    var newTask = this.state.currentTask;
    newTask.ncrType = newType;
    this.setState({
      currentTask: newTask
    });
  }

  handleOldNonconformanceType() {
    var type = this.getValue("NonconformanceType");

    // If type is "Process" or "Product", convert to Workmanship
    if (["Process", "Product"].includes(type)) {
      this.setValue("NonconformanceType", "Workmanship");
    }
  }

/* <------------------------> */
//#endregion
/* <------------------------> */


/* <------------------------> */
//#region COMPONENT FUNCTIONS
/* <------------------------> */

  handleFormErrors = (error: boolean) => {
    this.setState({
      formError: error
    })
  }
  //Function to Quality Expediting stage. Multiple stages can show this component. 
  qualityExpediting() {
    return <QualityExpediting
      task={this.state.currentTask}
      fileAddHandler={this.addFileHandler}
      fileRemoveHandler={this.removeFileHandler}
      stageChange={this.stageChange}
      files={this.state.fileUpload.files}
      onError={this.handleFormErrors}
    />
  }

  //function to determine which component to show depending on stage. 
  additionalFields() {
    if (this.state.currentTask.assignedTo) {
      const ncrType = this.state.currentTask.ncrType;

      switch (this.state.currentTask.stage) {
        case Stage.EngineeringReview:
          return <EngineeringReview task={this.state.currentTask} />
        case Stage.SupplyChainReview:
          return <SupplyChainReview task={this.state.currentTask} fileAddHandler={this.addFileHandler} fileRemoveHandler={this.removeFileHandler}
            files={this.state.fileUpload.files} />
        case Stage.SMPMApproval:
          return <ApproveRejectField task={this.state.currentTask} dataName={NCRDataConstants.PM_SM_APPROVAL} title={NCRDataConstants.PM_SM_APPROVAL_TITLE} 
            rejectionCommentsDataName={NCRDataConstants.PM_SM_REJECTION_COMMENTS} approverNameDataName={NCRDataConstants.PM_SM_APPROVAL_NAME} 
            approveDateDataName={NCRDataConstants.PM_SM_APPROVAL_DATE} eventHandler={this.approvalChange} 
            rejectToDataName={NCRDataConstants.PM_SM_REJECTION_REJECT_TO} rejectTo={ncrType.toUpperCase() === "ENGINEERING" ? ncrType : ""} 
            rejectToEventHandler={this.rejectToChange} />
        case Stage.DCAcknowledgment:
          return <AcknowledgmentField task={this.state.currentTask} dataName={NCRDataConstants.DC_ACKNOWLEDGMENT} title={NCRDataConstants.DC_ACKNOWLEDGMENT_VALUE} 
            header={NCRDataConstants.DC_ACKNOWLEDGMENT_HEADER} eventHandler={this.dcAcknowledgmentChange} />
        case Stage.QualityApproval:
          return <ApproveRejectField task={this.state.currentTask} dataName={NCRDataConstants.QUALITY_APPROVAL} title={NCRDataConstants.QUALITY_APPROVAL_TITLE} 
            rejectionCommentsDataName={NCRDataConstants.QUALITY_REJECTION_COMMENTS} eventHandler={this.approvalChange}
            rejectToDataName={NCRDataConstants.QUALITY_REJECTION_REJECT_TO} rejectTo={ncrType.toUpperCase() === "ENGINEERING" ? ncrType : ""} 
            rejectToEventHandler={this.rejectToChange} />
        case Stage.QualitExpediting:
          return this.qualityExpediting()
        case Stage.CorporateAnalysis:
          return this.qualityExpediting()
        case Stage.Implementation:
          return this.qualityExpediting()
        default:
          return ""
      }
    }
  }

  // function to determine if the current user is the assignTo user
  correctUser() {
    var claimed = this.state.currentTask.assignedTo;
    var userEmail = this.context.accounts[0].username;

    return claimed.toLowerCase() === userEmail.toLowerCase();
  }

  // Function to get a field's value
  getValue(dataName: string) {
    var result = this.state.currentTask.screen.screenFields.find(x => x.dataName === dataName)?.value;

    // Return empty string if not found, otherwise return result
    return result ? result : "";
  }

  // Function to set a field's value
  setValue(dataName: string, value: string) {
    var newCurrentTask = this.state.currentTask;
    var fieldIndex = newCurrentTask.screen.screenFields.findIndex(x => x.dataName === dataName);

    // Set new value or add value to screenfields
    if (fieldIndex !== -1) {
      newCurrentTask.screen.screenFields[fieldIndex].value = value;
    }
    else {
      newCurrentTask.screen.screenFields.push({
        dataName: dataName,
        value: value,
        type: FieldType.Hidden,
        title: "",
        hintText: "",
        buttonText: "",
        buttonColor: "",
        headerColor: "",
        textColor: "",
        time: false,
        readOnly: false,
        required: false,
        options: []
      })
    }

    this.setState({
      currentTask: newCurrentTask
    });
  }

  // Function to set WorkType based on WorkCode
  async setWorkType(workCode: string) {
    var workTypeQuery = {
      query: [
        {
          column: 1,
          values: [this.state.currentTask.projectNumber]
        },
        {
          column: 2,
          values: [workCode]
        },
      ]
    };

    try {
      // we want to use the enterprise data if the JDESource field exists
      // check if the form entry used Enterprise Data
      const targetDS = this.state.currentTask.screen.screenFields.find(x => x.dataName === 'JDESource')?.value === 'Enterprise' ? "BOOMI_WORKCODES_ENTERPRISE" : "BOOMI_WORKCODES";

      // Get work code data
      const workCodeData = (await DataService.searchDataSourceDataWithQuery(targetDS, 8, workTypeQuery)).data;

      if (workCodeData) {
          this.setValue("WorkType", workCodeData[0]);
      }
    } catch (e) {
        console.log("An error occurred setting Work Type", e);
    }
  }

/* <------------------------> */
//#endregion
/* <------------------------> */

//componentDidMount method is called after the component is rendered. 
  componentDidMount() {
    this.getCurrentTask(this.state.taskId);
    this.getTaskComments(this.state.taskId);
    this.getTaskHistory(this.state.taskId);
    appInsights.trackPageView({name: "Task Details page"});
  }

  alertComponent(value: string, variant: string, buttonText?: string) {
    return (
      <div>
        <br/>
        <Alert variant={variant}>
          <input type="text" className="input-hidden" autoFocus={true}/>
          {value}
          { // if button text has a value, show the button, else return null
          buttonText ?
            <div>
              <hr />
              <div className="d-flex justify-content-end">
                <Button onClick={() => this.setState({backToTaskPage : true})} variant={variant}>
                  {buttonText}
                </Button>
              </div>
            </div> : null}
        </Alert>
      </div>
    )
  }

  checkIfCompletedOrAssigned () {
    // Display that the task is already complete
    if (this.state.currentTask.completed) {
      return this.alertComponent(
        "This task has already been completed.",
        "info",
        "Go Back"
      )
    }
    // Display that the task is claimed by another user
    else if (!this.correctUser() && this.state.currentTask.assignedTo !== "") {
      return this.alertComponent(
        `This task has been claimed by ${this.state.currentTask.assignedToName}. You may view the task but you cannot edit it.`, 
        "warning",
        "Go Back"
      )
    }
    // Display that the task is not claimable by the current user (not claimed and user not in the groupUsers list)
    else if (this.state.currentTask.assignedTo === "" && !this.state.currentTask.groupUsers?.find(x => x.employeeEmail === this.context.accounts[0].username)) {
      return this.alertComponent(
        `This task cannot be claimed because you do not have the appropriate permissions. You may view the task but you cannot edit it.`, 
        "warning",
        "Go Back"
      )
    }
    else {
      return null
    }
  }

  itemizedCostVisibility() {
    return this.getValue("CompleteNCRAtInitiation") === "Yes" || 
            this.state.currentTask.tier === 3 || 
            (this.state.currentTask.tier === 2 && this.getValue("NonconformanceType") === "Material") || 
            (this.state.currentTask.stage > Stage.QualityRefinement && parseFloat(this.getValue(NCRDataConstants.COMBINED_TOTAL_COST)) >= 25000)
  }

  itemizedCostSection(task: ITask, headerTitle: string, headerHintText: string, blattnerCostDataName: string, nonBlattnerCostDataName: string, explainCostDataName: string) {
    return(
      <SectionWrapper headerTitle={headerTitle} headerHintText={headerHintText}
        visible={this.itemizedCostVisibility()}
      >
        {/* Blattner Cost */}
        <NumberField dataName={blattnerCostDataName} title={"Blattner Cost"} value={this.getValue(blattnerCostDataName)}
          decimals={2}
          readonly={![Stage.SupplyChainReview, Stage.QualityRefinement].includes(task.stage)}
          required
          onchange={this.handleItemizedCostChange}
        />
        {/* Non-Blattner Cost */}
        <NumberField dataName={nonBlattnerCostDataName} title={"Non-Blattner Cost"} value={this.getValue(nonBlattnerCostDataName)}
          decimals={2}
          readonly={![Stage.SupplyChainReview, Stage.QualityRefinement].includes(task.stage)}
          required
          onchange={this.handleItemizedCostChange}
        />
        {/* Explanation of Cost */}
        <TextField dataName={explainCostDataName} title={"Explanation of Cost"} value={this.getValue(explainCostDataName)}
          readonly={![Stage.SupplyChainReview, Stage.QualityRefinement].includes(task.stage)}
          required
          visible={Number(this.getValue(blattnerCostDataName)) + Number(this.getValue(nonBlattnerCostDataName)) > 0}
        />
      </SectionWrapper>
    )
  }

  displayPhotosDocumentsSection(textFieldTitle: string, textFieldDataName: string, photoDataName: string, documentDataName: string) {
    // Create empty list of fields
    var fields = this.state.currentTask.screen.screenFields.slice(0, 0);
    
    // Add fields where data name includes dataName
    this.state.currentTask.screen.screenFields.forEach((field) => {
      if (field.dataName.includes(textFieldDataName)) {
        fields.push(field)
      }
    })

    // Return each photo/document by looping through the fields
    return(
      <SectionWrapper>
        {fields.map((field, i) => {
          return(
            <div key={field.dataName}>
              <TextField dataName={`${i+1}.${textFieldDataName}`} title={textFieldTitle} value={this.getValue(`${i+1}.${textFieldDataName}`)}
                readonly
                visible={this.getValue(`${i+1}.${textFieldDataName}`) !== ""}
              />
              <MediaField dataName={`${i+1}.${photoDataName}`} title={""} value={this.getValue(`${i+1}.${photoDataName}`)}
                visible={this.getValue(`${i+1}.${photoDataName}`) !== ""}
              />
              <FileField title={""} value={this.getValue(`${i+1}.${documentDataName}`)} 
                visible={this.getValue(`${i+1}.${documentDataName}`) !== ""}
              />
            </div>
          )
        })}
      </SectionWrapper>
    );
  }

  render() {

/* <------------------------> */
//#region STATE AND RENDER LOGIC
/* <------------------------> */
    const { 
      currentTask,
      openDeleteModal,
      openReassignModal,
      deleteReason,
      selectOptionsReassign,
      backToTaskPage,
      loadingComplete,
      loadingMessage,
      singleOptionsReassign,
      tab,
      taskComments,
      taskCommentsLoaded,
      taskHistory,
      approval,
      rejectTo,
      dcAcknowledgment,
      error,
      workCodeOptions,
      defectFailureModesOptions,
      subcontractorNameOptions,
      supplierVendorNameOptions
     } = this.state;

     //returns back to task page when save and exit or submit button clicked. 
     if (backToTaskPage) {
      return <Redirect to = {{ pathname: "/tasks" }} />
    }

    //checks if approve or denied is selected on the SMPMApproval and QualityApproval stages before showing submit button. 
    let submitButton;
    if (currentTask.id != null && this.correctUser())
    {
      var isReadOnly = false;
      
      // SM/PM Approval & Quality Approval
      if (currentTask.stage === Stage.SMPMApproval || currentTask.stage === Stage.QualityApproval) {
        isReadOnly = !approval || 
            (approval === NCRDataConstants.APPROVAL_REJECT && ((currentTask.ncrType.toUpperCase() === "WORKMANSHIP" ||
              (currentTask.ncrType.toUpperCase() === "ENGINEERING" && rejectTo === NCRDataConstants.APPROVAL_REJECT_ASSIGNEE)) &&           
              (!currentTask.rejectionUsers || currentTask.rejectionUsers.length === 0)))
      }

      // DC Acknowledgment
      if (currentTask.stage === Stage.DCAcknowledgment) {
        isReadOnly =  !dcAcknowledgment;
      }

      submitButton = (
        <div className="ButtonDiv">
          <br />
          <ButtonType
            dataName="Submit"
            color="blue"
            buttonText="Submit"
            type="submit"
            size="lg"
            readOnly={isReadOnly || !loadingComplete || this.state.formError}
            eventHandler={this.setSubmitButton}
          />
        </div>
      );
    }
    
/* <------------------------> */
//#endregion
/* <------------------------> */

    return (

/* <------------------------> */
//#region RETURN
/* <------------------------> */
    <div className='row w-75'>
      {
        <div>
          <LoadingWrapper showLoading={!loadingComplete} loadingMessage={loadingMessage} windSpinner windSpinnerScale={3}>
          {this.checkIfCompletedOrAssigned()}
          {error ? this.alertComponent("An error occurred.  Please try again or contact IT Support.", "danger") : null }
          <Card>
            <Card.Header>
              <Nav fill variant="tabs" activeKey={this.state.tab} onSelect={this.handleTabChange}>
                <Nav.Item>
                  <Nav.Link eventKey="NCR">NCR</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Discussion" disabled={taskCommentsLoaded ? false : true}>Discussion {taskComments.length > 0 ? "(" + taskComments.length.toString() + ")" : null}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="History">History</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            <Card.Body>
              {tab === "NCR" ? 
              <div>
              <div style={{textAlign: "center", marginTop: "1000"}}>

              <Modal show={openDeleteModal} onHide={this.onCloseDeleteModal} size="lg">
                <form className="needs-validation" onSubmit={this.modalTaskHandler} noValidate>
                  <Modal.Header closeButton>
                      <Modal.Title>Are you sure you want to delete this NCR?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="form-group">
                        <TextArea dataName="Messaging" value={deleteReason} title="Reason for deleting NCR" onchange={this.handleChangeTextArea} required={true} />
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <br />
                      <ButtonType dataName="submit" color="blue" size="lg" type="submit" buttonText="Yes" eventHandler={ () => {this.deleteTask(currentTask.id, deleteReason)}} />
                      <ButtonType dataName="submit" color="blue" size="lg" buttonText="No" eventHandler={this.onCloseDeleteModal} />
                      <br />
                    </Modal.Footer>
                  </form>
                </Modal>

              <Modal show={openReassignModal} onHide={this.onCloseReassignModal}>
                <form className="needs-validation" onSubmit={this.modalTaskHandler} noValidate>
                  <Modal.Header closeButton>
                    <Modal.Title>Reassign Task To:</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="form-group">
                      {this.state.currentTask.stage > Stage.QualitExpediting || this.state.qualityExpeditingStage > Stage.QualitExpediting ? 
                        <ADSearchField dataName="reassignTo" title="" required={true} onchange={this.onADSearchChange} optionValue="Email" />
                        :
                        <SelectField onchange={this.onChangeReassignOption} required={true}
                            options={selectOptionsReassign} dataName="reassignTo" value={singleOptionsReassign} title="" />
                      }
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                  <br />
                    <ButtonType dataName="submit" color="blue" size="lg" type="submit" buttonText="Reassign Task" eventHandler={ () => {this.claimTask(singleOptionsReassign, currentTask.id)}} />
                    <ButtonType dataName="submit" color="blue" size="lg" buttonText="Cancel" eventHandler={this.onCloseReassignModal} />
                    <br />
                  </Modal.Footer>
                </form>
              </Modal>

            </div>
            <form className="needs-validation" onSubmit={this.submitHandler} ref={this.formRef} noValidate>
              <div>
                {this.checkIfTaskIsClaimed()}
              </div>
              <h1 className="header mt-5 text-center">{currentTask.screen.name}</h1>
              <div className="mt-4">
              <div><p>Current Stage is <i>{StageLabel[currentTask.stage]}</i></p></div>

              {/* Disable all fields if not assigned to logged in user */}
              <fieldset disabled={currentTask.assignedTo !== this.context.accounts[0].username}>
                {/* NCR BASE DETAILS */}
                <SectionWrapper headerTitle={`${currentTask.projectNumber} - ${currentTask.projectName}`}>
                  {/* Rejection Comments */}
                  <TextField dataName={"PMSM_CommentsRejection"} title={"PM/SM Rejection Comments"} value={this.getValue("PMSM_CommentsRejection")}
                    readonly
                    visible={this.getValue("PMSM_CommentsRejection") !== ""}
                  />
                  <TextField dataName={"Quality_CommentsRejection"} title={"Quality Rejection Comments"} value={this.getValue("Quality_CommentsRejection")}
                    readonly
                    visible={this.getValue("Quality_CommentsRejection") !== ""}
                  />
                  {/* Detected By */}
                  <TextField dataName={"DetectedBy"} title={"Detected By"} value={this.getValue("DetectedBy")}
                    readonly
                  />
                  {/* Initial Cost Estimate */}
                  <NumberField dataName={"CostEstimate"} title={"Initial Cost Estimate"} value={this.getValue("CostEstimate")}
                    readonly
                  />
                  {/* Assign To */}
                  <TextField dataName={"Assignee"} title={"Assign To"} value={this.getValue("Assignee")}
                    readonly
                    visible={this.getValue("Assignee") !== ""}
                  />
                  {/* Nonconformance Discovery Date */}
                  <DateField dataName={"NcrDate"} title={"Nonconformance Discovery Date"} value={this.getValue("NcrDate")} 
                    readonly={currentTask.stage !== Stage.QualityRefinement}
                    required
                  />
                  {/* Target Completion Date */}
                  <DateField dataName={"TargetDate"} title={"Target Completion Date"} value={this.getValue("TargetDate")} 
                    readonly={currentTask.stage !== Stage.QualityRefinement}
                    required
                    visible={currentTask.stage >= Stage.QualityRefinement && currentTask.tier === 3}
                  />
                  {/* Work Code - match only the work code as it comes from the form entry */}
                  <SearchField dataName={"WorkCode"} title={"Work Code"} value={this.getValue("WorkCode").match('(^\\d+)')?.[0] ?? ''}
                    options={workCodeOptions}
                    readonly={currentTask.stage !== Stage.QualityRefinement}
                    required
                    maxDisplayOptions={100}
                    findValueIncludes // So that old values (just a number) are still found
                    onchange={(e) => this.searchFieldValueChangeHandler("WorkCode", e)}
                  />
                  {/* Type of Nonconformance */}
                  <SelectField dataName={"NonconformanceType"} title={"Type of Nonconformance"} value={this.getValue("NonconformanceType")}
                    options={[
                      {value: "Material"},
                      {value: "Workmanship"}
                    ]}
                    readonly={currentTask.stage !== Stage.QualityRefinement}
                    required
                    onchange={(e) => this.choicesValueChangeHandler("NonconformanceType", e)}
                  />
                  {/* Responsible Party */}
                  <SelectField dataName={"ResponsibleParty"} title={"Responsible Party"} value={this.getValue("ResponsibleParty")}
                    options={this.getValue("NonconformanceType") === "Material" ? [
                      {value: "Owner Supplier/Vendor"},
                      {value: "Supplier/Vendor"}
                    ]
                    : [
                      {value: "Blattner"},
                      {value: "Engineering"},
                      {value: "Subcontractor"}
                    ]}
                    readonly={currentTask.stage !== Stage.QualityRefinement}
                    required
                    onchange={(e) => this.choicesValueChangeHandler("ResponsibleParty", e)}
                  />
                  {/* Search By */}
                  <SelectField dataName={"SearchBy"} title={"Search By"} value={this.getValue("SearchBy")}
                    options={this.getValue("NonconformanceType") === "Material" ? [
                      {value: "Supplier/Vendor Name"},
                      {value: "Other Supplier/Vendor Name"}
                    ]
                    : [
                      {value: "Subcontractor Name"},
                      {value: "Other Subcontractor Name"}
                    ]}
                    visible={(this.getValue("ResponsibleParty").includes("Supplier/Vendor") || this.getValue("ResponsibleParty") === "Subcontractor") && currentTask.stage === Stage.QualityRefinement}
                    required
                    onchange={(e) => this.choicesValueChangeHandler("SearchBy", e)}
                  />
                  {/* Subcontractor Name */}
                  <SearchField dataName={"SubcontractorName"} title={"Subcontractor Name"} value={this.getValue("SubcontractorName")}
                    options={subcontractorNameOptions}
                    visible={(this.getValue("ResponsibleParty") === "Subcontractor" && this.getValue("SearchBy") === "Subcontractor Name") ||
                    (currentTask.stage < Stage.QualityRefinement && this.getValue("SubcontractorName") !== "")}
                    readonly={currentTask.stage !== Stage.QualityRefinement}
                    required
                    maxDisplayOptions={100}
                    onchange={(e) => this.searchFieldValueChangeHandler("SubcontractorName", e)}
                  />
                  <TextField dataName={"OtherSubcontractorName"} title={"Other Subcontractor Name"} value={this.getValue("OtherSubcontractorName")}
                    readonly={currentTask.stage !== Stage.QualityRefinement}
                    required
                    visible={(this.getValue("ResponsibleParty") === "Subcontractor" && this.getValue("SearchBy") === "Other Subcontractor Name") ||
                    (currentTask.stage < Stage.QualityRefinement && this.getValue("OtherSubcontractorName") !== "")}
                  />
                  {/* Supplier/Vendor Name */}
                  <SearchField dataName={"SupplierVendorName"} title={"Supplier/Vendor Name"} value={this.getValue("SupplierVendorName")}
                    options={supplierVendorNameOptions}
                    visible={(this.getValue("ResponsibleParty").includes("Supplier/Vendor") && this.getValue("SearchBy") === "Supplier/Vendor Name") ||
                      (currentTask.stage < Stage.QualityRefinement && this.getValue("SupplierVendorName") !== "")}
                    readonly={currentTask.stage !== Stage.QualityRefinement}
                    required
                    maxDisplayOptions={100}
                    onchange={(e) => this.searchFieldValueChangeHandler("SupplierVendorName", e)}
                  />
                  <TextField dataName={"OtherSupplierVendorName"} title={"Other Supplier/Vendor Name"} value={this.getValue("OtherSupplierVendorName")}
                    readonly={currentTask.stage !== Stage.QualityRefinement}
                    required
                    visible={(this.getValue("ResponsibleParty").includes("Supplier/Vendor") && this.getValue("SearchBy") === "Other Supplier/Vendor Name") ||
                    (currentTask.stage < Stage.QualityRefinement && this.getValue("OtherSupplierVendorName") !== "")}
                  />
                  {/* Defect Failure Modes */}
                  <SelectField dataName={"DefectFailureModes"} title={"Defect Failure Modes"} value={this.getValue("DefectFailureModes")}
                    options={defectFailureModesOptions}
                    hintText={"The way a material/product has failed to meet specifications, prints or contractual obligations. Failure modes are errors or nonconformances and can be potential or actual."}
                    readonly={![Stage.SupplyChainReview, Stage.QualityRefinement].includes(currentTask.stage)}
                    required
                    visible={this.getValue("NonconformanceType") === "Material"}
                    onchange={(e) => this.choicesValueChangeHandler("DefectFailureModes", e)}
                  />
                  {/* Document */}
                  {this.displayPhotosDocumentsSection("Document Title", "DocumentTitle", "", "ChooseFile")}
                  {/* Nonconformance Description */}
                  <TextArea dataName={"NonconformanceDescription"} title={"Nonconformance Description"} value={this.getValue("NonconformanceDescription")}
                    readonly={currentTask.stage !== Stage.QualityRefinement}
                    required
                    rows={3}
                  />
                  {/* Nonconformance Location Details */}
                  <TextArea dataName={"NonconformanceLocation"} title={"Nonconformance Location Details"} value={this.getValue("NonconformanceLocation")}
                    readonly={currentTask.stage !== Stage.QualityRefinement}
                    required
                    rows={3}
                  />
                  {/* Mechanical or Substantial Completion Will Be Impacted */}
                  <SelectField dataName={"CompletionImpactedYN"} title={"Mechanical or Substantial Completion Will Be Impacted"} value={this.getValue("CompletionImpactedYN")}
                    options={[{value: "Yes"}, {value: "No"}]} 
                    readonly
                    onchange={(e) => this.choicesValueChangeHandler("CompletionImpactedYN", e)}
                  />
                  {/* Confirmed With Your Direct Supervisor */}
                  <SelectField dataName={"DoubleCheckWithSM"} title={"Confirmed With Your Direct Supervisor"} value={this.getValue("DoubleCheckWithSM")}
                    options={[{value: "Yes"}, {value: "No"}]} 
                    readonly
                    onchange={(e) => this.choicesValueChangeHandler("DoubleCheckWithSM", e)}
                  />
                  {/* Photos/Documents */}
                  {this.displayPhotosDocumentsSection("Photo/Document Title", "TitleName", "UploadPhoto", "UploadDocument")}
                  {/* Complete Full NCR at Initiation */}
                  <SelectField dataName={"CompleteNCRAtInitiation"} title={"Complete Full NCR at Initiation"} value={this.getValue("CompleteNCRAtInitiation")}
                    options={[{value: "Yes"}, {value: "No"}]} 
                    readonly
                    visible={this.getValue("CompleteNCRAtInitiation") !== ""}
                    onchange={(e) => this.choicesValueChangeHandler("CompleteNCRAtInitiation", e)}
                  />
                </SectionWrapper>

                {/* RESOLUTION, CAUSE, & ACTION */}
                <SectionWrapper headerTitle="Resolution, Cause, & Action"
                  visible={currentTask.stage > Stage.EngineeringReview || this.getValue("CompleteNCRAtInitiation") === "Yes" || currentTask.tier === 3}
                >
                  {/* Resolution Proposal */}
                  <SelectField dataName={"ResolutionProposal"} title={"Resolution Proposal"} value={this.getValue("ResolutionProposal")}
                    options={[
                      {value: "Redesign"},
                      {value: "Return to Supplier/Replace"},
                      {value: "Rework/Repair"},
                      {value: "Use in AS IS Condition"}
                    ]}
                    allowSelectOption
                    readonly={currentTask.stage !== Stage.QualityRefinement}
                    required={this.getValue("CompleteNCRAtInitiation") === "Yes" || currentTask.tier === 3}
                    visible={currentTask.stage >= Stage.QualityRefinement || this.getValue("ResolutionProposal") !== ""}
                    onchange={(e) => this.choicesValueChangeHandler("ResolutionProposal", e)}
                  />
                  {/* Corrective Action Details */}
                  <TextArea dataName={"CorrectiveAction"} title={"Corrective Action Details"} value={this.getValue("CorrectiveAction")}
                    hintText={
                      currentTask.stage === Stage.SupplyChainReview || parseFloat(this.getValue(NCRDataConstants.COMBINED_TOTAL_COST)) >= 25000 ?
                        "Describe, in detail, how Resolution Proposal will be achieved."
                        : currentTask.stage === Stage.QualityRefinement ?
                          "When not applicable, this can be left blank."
                          : ""
                    }
                    readonly={![Stage.SupplyChainReview, Stage.QualityRefinement].includes(currentTask.stage)}
                    required={currentTask.stage === Stage.SupplyChainReview || parseFloat(this.getValue(NCRDataConstants.COMBINED_TOTAL_COST)) >= 25000}
                    visible={currentTask.stage < Stage.QualitExpediting || this.getValue("CorrectiveAction") !== ""}
                    rows={3}
                  />
                  {/* Root Cause Analysis */}
                  <TextArea dataName={"CauseAnalysis"} title={"Root Cause Analysis"} value={this.getValue("CauseAnalysis")}
                    hintText={
                      parseFloat(this.getValue(NCRDataConstants.COMBINED_TOTAL_COST)) < 25000 ?
                        currentTask.stage === Stage.QualityRefinement ?
                        "When not applicable, this can be left blank."
                        : ""
                      : ""
                    }
                    readonly={![Stage.SupplyChainReview, Stage.QualityRefinement].includes(currentTask.stage)}
                    required={currentTask.stage === Stage.SupplyChainReview || parseFloat(this.getValue(NCRDataConstants.COMBINED_TOTAL_COST)) >= 25000}
                    visible={currentTask.stage < Stage.QualitExpediting || this.getValue("CauseAnalysis") !== ""}
                    rows={3}
                  />
                  {/* Preventative Action Details */}
                  <TextArea dataName={"PreventativeAction"} title={"Preventative Action Details"} value={this.getValue("PreventativeAction")}
                    hintText={
                      currentTask.stage === Stage.SupplyChainReview || parseFloat(this.getValue(NCRDataConstants.COMBINED_TOTAL_COST)) >= 25000 ?
                        "The Preventative Action(s) must address the Root Cause."
                        : currentTask.stage === Stage.QualityRefinement ?
                          "When not applicable, this can be left blank."
                          : ""
                    }
                    readonly={![Stage.SupplyChainReview, Stage.QualityRefinement].includes(currentTask.stage)}
                    required={currentTask.stage === Stage.SupplyChainReview || parseFloat(this.getValue(NCRDataConstants.COMBINED_TOTAL_COST)) >= 25000}
                    visible={currentTask.stage < Stage.QualitExpediting || this.getValue("PreventativeAction") !== ""}
                    rows={3}
                  />
                  {/* Quality Exposure */}
                  <SelectField dataName={"QualityRiskTopics"} title={"Quality Exposure"} value={this.getValue("QualityRiskTopics")}
                    options={[
                      {value: "Labeling/Marking"},
                      {value: "Material Condition/Manufacture"},
                      {value: "Material Handling/Storage"},
                      {value: "Measure/Align"},
                      {value: "Pre/Post Job Inspection"},
                      {value: "Reference/Procedure"},
                      {value: "Tool/Equipment"}
                    ]} 
                    readonly={currentTask.stage !== Stage.QualityRefinement}
                    required
                    visible={currentTask.stage >= Stage.QualityRefinement}
                    onchange={(e) => this.choicesValueChangeHandler("QualityRiskTopics", e)}
                  />
                </SectionWrapper>

                {/* NONCONFORMANCE INVESTIGATION COST */}
                {this.itemizedCostSection(
                  currentTask,
                  "Nonconformance Investigation Cost",
                  "Includes all team members involved",
                  NCRDataConstants.INVESTIGATION_BLATTNER_COST,
                  NCRDataConstants.INVESTIGATION_NON_BLATTNER_COST,
                  NCRDataConstants.INVESTIGATION_COST_EXPLANATION
                )}
                
                {/* WORK STOPPAGE COST */}
                {this.itemizedCostSection(
                  currentTask,
                  "Work Stoppage Cost",
                  "Include cost related to work stoppage",
                  NCRDataConstants.WORK_BLATTNER_COST,
                  NCRDataConstants.WORK_NON_BLATTNER_COST,
                  NCRDataConstants.WORK_COST_EXPLANATION
                )}

                {/* ADDITIONAL MEETING COST */}
                {this.itemizedCostSection(
                  currentTask,
                  "Additional Meeting Cost",
                  "Include meeting costs related to nonconformance",
                  NCRDataConstants.ADDITIONAL_BLATTNER_COST,
                  NCRDataConstants.ADDITIONAL_NON_BLATTNER_COST,
                  NCRDataConstants.ADDITIONAL_COST_EXPLANATION
                )}
                
                {/* REMOVAL/DEMOLITION COST */}
                {this.itemizedCostSection(
                  currentTask,
                  "Removal/Demolition Cost",
                  "Include costs for labor, equipment, materials, engineering, etc",
                  NCRDataConstants.REMOVAL_BLATTNER_COST,
                  NCRDataConstants.REMOVAL_NON_BLATTNER_COST,
                  NCRDataConstants.REMOVAL_COST_EXPLANATION
                )}

                {/* REINSTALLATION COST */}
                {this.itemizedCostSection(
                  currentTask,
                  "Reinstallation Cost",
                  "Include costs for labor, equipment, materials, engineering, etc.",
                  NCRDataConstants.REINSTALLATION_BLATTNER_COST,
                  NCRDataConstants.REINSTALLATION_NON_BLATTNER_COST,
                  NCRDataConstants.REINSTALLATION_COST_EXPLANATION
                )}

                {/* OTHER/ADDITIONAL COST */}
                {this.itemizedCostSection(
                  currentTask,
                  "Other/Additional Cost",
                  "Include any additional costs not defined above",
                  NCRDataConstants.OTHER_BLATTNER_COST,
                  NCRDataConstants.OTHER_NON_BLATTNER_COST,
                  NCRDataConstants.OTHER_COST_EXPLANATION
                )}

                {/* TOTAL COSTS */}
                <SectionWrapper headerTitle="Total Cost(s)"
                  visible={currentTask.stage > Stage.EngineeringReview || this.getValue("CompleteNCRAtInitiation") === "Yes" || currentTask.tier === 3}
                >
                  {/* Blattner Total Cost */}
                  <NumberField dataName={NCRDataConstants.BLATTNER_TOTAL_COST} title={"Blattner Total Cost"} value={this.getValue(NCRDataConstants.BLATTNER_TOTAL_COST)}
                    decimals={2}
                    readonly={this.itemizedCostVisibility() || currentTask.stage > Stage.QualityRefinement}
                    required
                    onchange={this.handleTotalCostChange}
                  />
                  {/* Non-Blattner Total Cost */}
                  <NumberField dataName={NCRDataConstants.NON_BLATTNER_TOTAL_COST} title={"Non-Blattner Total Cost"} value={this.getValue(NCRDataConstants.NON_BLATTNER_TOTAL_COST)}
                    decimals={2}
                    readonly={this.itemizedCostVisibility() || currentTask.stage > Stage.QualityRefinement}
                    required
                    onchange={this.handleTotalCostChange}
                  />
                  {/* Combined Total Cost */}
                  <NumberField dataName={NCRDataConstants.COMBINED_TOTAL_COST} title={"Combined Total Cost"} value={this.getValue(NCRDataConstants.COMBINED_TOTAL_COST)}
                    decimals={2}
                    readonly
                  />
                </SectionWrapper>

                {/* PROJECT TEAM MEMBERS */}
                <SectionWrapper headerTitle="Project Team Members">
                  {/* Foreperson/Leadperson */}
                  <TextField dataName={"Foreperson"} title={"Primary Foreperson/Leadperson"} value={this.getValue("Foreperson")}
                    readonly
                    visible={this.getValue("Foreperson") !== ""}
                  />
                  <TextField dataName={"Foreperson2"} title={"Secondary Foreperson/Leadperson"} value={this.getValue("Foreperson2")}
                    readonly
                    visible={this.getValue("Foreperson2") !== ""}
                  />
                  {/* Trade Superintendent */}
                  <TextField dataName={"TradeSup"} title={"Primary Trade Superintendent"} value={this.getValue("TradeSup")}
                    readonly
                    visible={this.getValue("TradeSup") !== ""}
                  />
                  <TextField dataName={"TradeSup2"} title={"Secondary Trade Superintendent"} value={this.getValue("TradeSup2")}
                    readonly
                    visible={this.getValue("TradeSup2") !== ""}
                  />
                </SectionWrapper>

                {/* ADDITIONAL ITEMS (DISPLAY ONLY) */}
                <SectionWrapper
                  visible={currentTask.stage > Stage.SupplyChainReview}
                >
                  {this.displayPhotosDocumentsSection("Photo/Document Title", "AdditionalPhotoTitle", "", "AdditionalPhotoURL")}
                </SectionWrapper>

                {/* ENGINEERING (DISPLAY ONLY) */}
                <SectionWrapper headerTitle="Engineering"
                  visible={currentTask.stage > Stage.EngineeringReview && this.getValue("EngineerPerformedBy") !== ""}
                >
                  <TextField dataName={"EngineerComments"} title={"Comments"} value={this.getValue("EngineerComments")}
                    readonly
                  />
                  <TextField dataName={"EngineerPerformedBy"} title={"Engineering Performed By"} value={this.getValue("EngineerPerformedBy")}
                    readonly
                  />
                  <TextField dataName={"ExtEngineerFirm"} title={"External Engineering Firm"} value={this.getValue("ExtEngineerFirm")}
                    readonly
                    visible={this.getValue("ExtEngineerFirm") !== ""}
                  />
                </SectionWrapper>

                {/* PM/SM APPROVAL (DISPLAY ONLY) */}
                <SectionWrapper headerTitle="PM/SM Approval"
                  visible={currentTask.stage > Stage.SMPMApproval && this.getValue("PMSMApproval") !== ""}
                >
                  <TextField dataName={"PMSMApproval"} title={"PM/SM Approval"} value={this.getValue("PMSMApproval")}
                    readonly
                  />
                </SectionWrapper>

                {/* DC ACKNOWLEDGEMENT (DISPLAY ONLY) */}
                <SectionWrapper headerTitle="DC Acknowledgment"
                  visible={currentTask.stage > Stage.DCAcknowledgment && this.getValue("DCAcknowledgment") !== ""}
                >
                  <TextField dataName={"DCAcknowledgment"} title={"DC Acknowledgment"} value={this.getValue("DCAcknowledgment")}
                    readonly
                  />
                </SectionWrapper>

                {/* QUALITY APPROVAL (DISPLAY ONLY) */}
                <SectionWrapper headerTitle="Quality Approval"
                  visible={currentTask.stage > Stage.QualityApproval && this.getValue("QualityApproval") !== ""}
                >
                  <TextField dataName={"QualityApproval"} title={"Quality Approval"} value={this.getValue("QualityApproval")}
                    readonly
                  />
                </SectionWrapper>

                {this.additionalFields()}
                  
                {submitButton}
              </fieldset>
              </div>
              
            </form>
            </div>
              : null}
            {tab === "Discussion" ?
                <TaskDiscussion taskID={this.state.taskId} preloadedComments={taskComments} addEvent={this.handleNewComment} />
                : null}
            {tab === "History" ? 
                <TaskHistory preloadedHistorys={taskHistory} />
                : null }
            </Card.Body>
          </Card>
          </LoadingWrapper>
        </div>
      }
    </div>


/* <------------------------> */
//#endregion
/* <------------------------> */

    );
  }
}