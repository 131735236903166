import React from 'react';
import { MsalContext } from '@azure/msal-react';
import ITestGroup from '../types/TestGroup';
import IProjectList from '../types/Dashboard/ProjectList';
import { appInsights } from '../services/appInsights';
import DashboardService from '../services/DashboardService';
import ProjectDropdown from '../components/ProjectDropdown';
import { Col, Fade, Modal, Spinner, Table } from 'react-bootstrap';
import Delayed from '../components/Delayed';
import FormApproverService from '../services/FormApproverService';
import FormApproverDetail from '../types/FormApprover/FormApproverDetail';
import CollapseField from '../components/CollapseField';
import FormApproverGroupDetail from '../types/FormApprover/FormApproverGroupDetail';
import AddButton from '../components/AddButton';
import RoleGroupService from '../services/RoleGroupService';
import RoleGroupList from '../types/RoleGroup/RoleGroupList';
import ButtonType from '../components/ButtonType';
import EmployeeDetail from '../types/Employee/EmployeeDetail';
import { RoleGroup } from '../enums/RoleGroup';
import DataService from '../services/DataService';
import SelectField from '../components/SelectField';
import TestService from '../services/TestService';
import LoadingWrapper from '../components/LoadingWrapper';

type Props = {
    updateProject?: (e: any) => void;
}

type State = {
    userGroups: ITestGroup[];
    projectLoading: boolean;
    selectedProject: string;
    projectList: IProjectList;
    approversLoading: boolean;
    approversList: FormApproverDetail[];
    approverGroups: FormApproverGroupDetail[];
    manualAddUpdates: RoleGroupList[];
    openModal: boolean;
    manualAddGroupName: string;
    userToManualAdd: EmployeeDetail;
    userAlreadyAdded: boolean;
    searchFieldOptions: EmployeeDetail[];
    canEdit: boolean;
    changesMade: boolean;
    saveLoading: boolean;
    saveStatus: string;
    showSaveStatus: boolean;
}

// Constant variables
const SAVESUCCESS = "SaveSuccess";
const SAVEERROR = "SaveError";
const GROUP_TO_JOBCODE = {
    "Civil FE": ["BFM700", "BFM603", "BFM602", "BFM352", "BFM663", "BFM654", "BFM652", "BFM657", "BFM655", "BFM658", "BFM664", "BFM653", "BOP651"],
    "Civil Superintendent" : ["BFM700", "BFM603", "BFM602", "BFM352", "BFM663", "BFM654", "BFM652", "BFM657", "BFM655", "BFM658", "BFM664", "BFM653", "BOP651"],
    "Collections FE": ["BFM700", "BFM603", "BFM602", "BFM652", "BFM653", "BFM352"],
    "Collections Superintendent" : ["BFM700", "BFM603", "BFM602", "BFM652", "BFM653", "BFM352"],
    "Commissioning Foreperson": ["BFM700", "BFM603", "BFM602", "BFM652", "BFM653", "BFM352", "BOP651", "OPF115", "OPP081"],
    "Electrical BESS FE" : ["BFM700", "BFM603", "BFM602", "BFM652", "BFM653", "BFM352"],
    "Electrical BESS Superintendent" : ["BFM700", "BFM603", "BFM602", "BFM652", "BFM653", "BFM352"],
    "Electrical FE": ["BFM700", "BFM603", "BFM602", "BFM652", "BFM653", "BFM352"],
    "Electrical Superintendent" : ["BFM700", "BFM603", "BFM602", "BFM652", "BFM653", "BFM352"],
    "Foundations BESS FE" : ["BFM700", "BFM603", "BFM602", "BFM352", "BFM663", "BFM654", "BFM652", "BFM657", "BFM655", "BFM658", "BFM664", "BFM653", "BOP651"],
    "Foundations BESS Superintendent" : ["BFM700", "BFM603", "BFM602", "BFM352", "BFM663", "BFM654", "BFM652", "BFM657", "BFM655", "BFM658", "BFM664", "BFM653", "BOP651"],
    "Foundations FE": ["BFM700", "BFM603", "BFM602", "BFM352", "BFM663", "BFM654", "BFM652", "BFM657", "BFM655", "BFM658", "BFM664", "BFM653", "BOP651"],
    "Foundations Superintendent" : ["BFM700", "BFM603", "BFM602", "BFM352", "BFM663", "BFM654", "BFM652", "BFM657", "BFM655", "BFM658", "BFM664", "BFM653", "BOP651"],
    "Installation FE": ["BFM700", "BFM603", "BFM602", "BFM352", "BFM663", "BFM654", "BFM652", "BFM657", "BFM655", "BFM658", "BFM664", "BFM653", "BOP651"],
    "Installation Superintendent" : ["BFM700", "BFM603", "BFM602", "BFM352", "BFM663", "BFM654", "BFM652", "BFM657", "BFM655", "BFM658", "BFM664", "BFM653", "BOP651"],
    "Overhead Collections FE" : ["BFM700", "BFM603", "BFM602", "BFM652", "BFM653", "BFM352"],
    "Overhead Collections Superintendent" : ["BFM700", "BFM603", "BFM602", "BFM652", "BFM653", "BFM352"],
    "Repower FE" : ["BFM700", "BFM603", "BFM602", "BFM352", "BFM663", "BFM654", "BFM652", "BFM657", "BFM655", "BFM658", "BFM664", "BFM653", "BOP651"],
    "Repower Superintendent" : ["BFM700", "BFM603", "BFM602", "BFM352", "BFM663", "BFM654", "BFM652", "BFM657", "BFM655", "BFM658", "BFM664", "BFM653", "BOP651"],
    "Substation FE": ["BFM700", "BFM603", "BFM602", "BFM652", "BFM653", "BFM352"],
    "Substation Superintendent" : ["BFM700", "BFM603", "BFM602", "BFM652", "BFM653", "BFM352"],
    "Switchyard FE": ["BFM700", "BFM603", "BFM602", "BFM652", "BFM653", "BFM352"],
    "Switchyard Superintendent" : ["BFM700", "BFM603", "BFM602", "BFM652", "BFM653", "BFM352"],
    "Transmission Line FE": ["BFM700", "BFM603", "BFM602", "BFM652", "BFM653", "BFM352"],
    "Transmission Line Superintendent" : ["BFM700", "BFM603", "BFM602", "BFM652", "BFM653", "BFM352"],
}

export default class QualityFormApproversPage extends React.Component<Props, State> {
    static contextType = MsalContext;
    constructor(props: any) {
        super(props);

        this.state = {
            userGroups: [],
            projectLoading: true,
            selectedProject: "",
            projectList: {
                projects: [],
                defaultProjectID: ""
            },
            approversLoading: false,
            approversList: [],
            approverGroups: [],
            manualAddUpdates: [],
            openModal: false,
            manualAddGroupName: "",
            userToManualAdd: {
                employeeID: "",
                employeeName: "",
                employeeEmail: "",
                manualAdd: false
            },
            userAlreadyAdded: false,
            searchFieldOptions: [],
            canEdit: false,
            changesMade: false,
            saveLoading: false,
            saveStatus: "",
            showSaveStatus: false
        }
    }

    async componentDidMount() {
        appInsights.trackPageView({ name: "Quality Form Approvers Page" });
        this.getProjects();
    }

    /**
     * Gets projects for the dropdown and sets canEdit permissions
     */
    async getProjects() {
        var userEmail = this.context.accounts[0].username.toLowerCase();

        // Get projects for dropdown
        await DashboardService.getProjects(userEmail)
        .then((response) => {
            this.setState({
                projectLoading: false,
                projectList: response.data
            });
            if (response.data.defaultProjectID && !this.state.selectedProject) {
                this.setState({
                    selectedProject: response.data.defaultProjectID
                });
            }
        }).catch((e) => {
            this.setState({
                projectLoading: false
            });
        });

        // Get logged in user's test groups
        var testGroups: any[] = [];
        await TestService.getGroup(userEmail)
        .then((response) => {
            if (response.data) {
                testGroups = response.data.map(x => x.group);
            }
        });

        
        // Get emails of users who can edit the page (BFM600, BFM601, BFM602, BFM603)
        var canEditEmails: any[] = [];
        var canEditQuery = {
            query: [{
                column: 10,
                values: ["BFM600", "BFM601", "BFM602", "BFM603"]
            }]
        }
        await DataService.searchDataSourceDataWithQuery("BOOMI_EMPLOYEES", 5, canEditQuery)
        .then((response) => {
            if (response.data) {
                canEditEmails = response.data.map((email: string) => email.toLowerCase());
            }
        });
        
        // Set canEdit permissions
        if (canEditEmails.includes(userEmail) || testGroups.includes("Quality_Supt")) {
            this.setState({
                canEdit: true
            });
        }
    }

    /**
     * Gets form approver data
     * @param project selected project's companyID
     */
    async getFormApprovers(project: string) {
        try {
            const qualityFormApproversPromise = Promise.all([
                FormApproverService.getFormApproversGroup(project),
                RoleGroupService.getAllGroupMembers(project, this.context.accounts[0].username),
                FormApproverService.getFormApprovers(project),
            ]);
            const [
                { data: ftcGroupDetails },
                { data: manualAddGroupDetails },
                { data: legacyGroupDetails },
            ] = await qualityFormApproversPromise;

            const groupsToFilter = ['DOT Coordinators', 'None'];
            const filteredManualAddGroup = manualAddGroupDetails
                .filter((e) => (!groupsToFilter.includes(e.roleGroupName)))
                .filter((e) => (e.roleGroupMembers.length !== 0));

            // Create the new list with manually added users
            const newApproverGroups: FormApproverGroupDetail[] = filteredManualAddGroup.map((group) => ({
                groupName: group.roleGroupName,
                groupEditable: true,
                users: group.roleGroupMembers.map((user) => ({ ...user, employeeEmail: 'N/A', manualAdd: true }))
            }))

            // Preppend approvers list with First to Claim Group approvers
            ftcGroupDetails.forEach(({ groupName, users }) => {
                const groupIndex = newApproverGroups.findIndex((g) => g.groupName === groupName)
                if (groupIndex === -1) {
                    newApproverGroups.push({
                        groupName,
                        groupEditable: true,
                        users: users.map((user) => ({ ...user, manualAdd: false }))
                    })
                } else {
                    users.forEach((user) => {
                        if (newApproverGroups[groupIndex].users.findIndex((u) => u.employeeID === user.employeeID) === -1) {
                            newApproverGroups[groupIndex].users.unshift({ ...user, manualAdd: false });
                        }
                    })
                }
            })

            // Append any Systems Workflows approvers that may be hold overs from legacy access
            legacyGroupDetails.forEach(({title, employeeID, employeeName, email: employeeEmail}) => {
                const groupName = title.trim() === "Commissioning Lead" ? "Commissioning Foreperson" : title.trim();
                // Need to remove the '/FCC' from the legacy names below to prevent duplicate groups.
                if (newApproverGroups.findIndex((g) => g.groupName === groupName.replace('/FCC', '')) === -1) {
                    newApproverGroups.push({
                        groupName,
                        groupEditable: false,
                        users: [{employeeID, employeeName, employeeEmail, manualAdd: false}]
                    })
                }
            })

            // setup mechanism for tracking manual add actions
            var newManualAddUpdates: any[] = filteredManualAddGroup.map((group) => ({
                addUpdates: [],
                removeUpdates: [],
                updatedGroup: [],
                companyID: project,
                groupName: RoleGroup[group.roleGroupName as keyof typeof RoleGroup],
                modifiedUser: this.context.accounts[0].username,
            }))

            this.setState({
                approverGroups: newApproverGroups,
                manualAddUpdates: newManualAddUpdates,
            })

        } catch (error) {
            console.log('An error occurred while getting the manual add data', error);
        }

        this.setState({
            approversLoading: false
        });
    }

    /**
     * Saves the updates to the database tables
     */
    async saveUpdates() {
        this.setState({
            saveLoading: true,
            changesMade: false,
            showSaveStatus: true
        });

        var responseError = false;

        this.state.manualAddUpdates.forEach((group) => {
            if (group.addUpdates.length || group.removeUpdates.length) {
                // Update each group
                RoleGroupService.updateGroupMembers(group)
                .then((response) => {
                    if (!(response.status >= 100 && response.status <= 399)) { // Successful response statuses are 100-399, checking if NOT in that range
                        responseError = true;
                    }
                });
            }
        });

        if (responseError) {
            this.setState({
                saveStatus: SAVEERROR,
                saveLoading: false
            });
        }
        else {
            this.setState({
                saveStatus: SAVESUCCESS,
                saveLoading: false
            });
        }

        // Set showSaveStatus back to false after 10 seconds
        setTimeout(() => {this.setState({showSaveStatus: false})}, 10000);
    }

    /**
     * Adds a user to a group
     */
    manualAdd() {
        // Update display groups
        var newApproverGroups = this.state.approverGroups.slice();
        var approverGroupIndex = newApproverGroups.findIndex(x => x.groupName === this.state.manualAddGroupName);
        newApproverGroups[approverGroupIndex].users.push(this.state.userToManualAdd);
        
        // Update RoleGroup DB table
        var newManualAddUpdates = this.state.manualAddUpdates.slice();
        var updateGroupIndex = newManualAddUpdates.findIndex(x => x.groupName === RoleGroup[this.state.manualAddGroupName as keyof typeof RoleGroup]);
        if (updateGroupIndex === -1) {
            // if the group isn't in the list add a group
            newManualAddUpdates.push({
                addUpdates: [],
                removeUpdates: [],
                updatedGroup: [],
                companyID: this.state.selectedProject,
                groupName: RoleGroup[this.state.manualAddGroupName as keyof typeof RoleGroup],
                modifiedUser: this.context.accounts[0].username,
            })
            updateGroupIndex = newManualAddUpdates.length - 1;
        }

        newManualAddUpdates[updateGroupIndex].addUpdates.push({
            employeeID: this.state.userToManualAdd.employeeID,
            employeeName: this.state.userToManualAdd.employeeName
        });

        // clear user from removal table
        let newRemoveUpdates = newManualAddUpdates[updateGroupIndex].removeUpdates
          .filter(x => x.employeeID !== this.state.userToManualAdd.employeeID);
        
        newManualAddUpdates[updateGroupIndex].removeUpdates = newRemoveUpdates;

        this.setState({
            approverGroups: newApproverGroups,
            manualAddUpdates: newManualAddUpdates,
            userToManualAdd: {
                employeeID: "",
                employeeName: "",
                employeeEmail: "",
                manualAdd: false
            },
            changesMade: true,
            openModal: false
        });
    }

    /**
     * Removes a user from a group
     * @param groupName name of the group
     * @param userToRemove user to remove from the group
     */
    manualRemove(groupName: string, userToRemove: EmployeeDetail) {
        // Update display groups
        var newApproverGroups = this.state.approverGroups.slice();
        var approverGroupIndex = newApproverGroups.findIndex(x => x.groupName === groupName);
        var approverUserIndex = newApproverGroups[approverGroupIndex].users.findIndex(x => x.employeeID === userToRemove.employeeID);
        newApproverGroups[approverGroupIndex].users.splice(approverUserIndex, 1);

        // Update manualAddUpdates state
        var newManualAddUpdates = this.state.manualAddUpdates.slice();
        var updateGroupIndex = newManualAddUpdates.findIndex(x => x.groupName === RoleGroup[groupName as keyof typeof RoleGroup]);

        // Update addUpdates list (if applicable), otherwise update removeUpdates list
        if (newManualAddUpdates[updateGroupIndex].addUpdates.findIndex(x => x.employeeID === userToRemove.employeeID) !== -1) {
            let newAddUpdates = newManualAddUpdates[updateGroupIndex].addUpdates
            .filter(x => x.employeeID !== userToRemove.employeeID);

            newManualAddUpdates[updateGroupIndex].addUpdates = newAddUpdates;
        }
        else {
            newManualAddUpdates[updateGroupIndex].removeUpdates.push({
                employeeID: userToRemove.employeeID,
                employeeName: userToRemove.employeeName
            });
        }

        this.setState({
            approverGroups: newApproverGroups,
            manualAddUpdates: newManualAddUpdates
        });

        this.checkChangesMade();
    }

    /**
     * Checks if any updates were made and sets the changesMade state
     */
    checkChangesMade() {
        var newChangesMade = false;

        this.state.manualAddUpdates.forEach((group) => {
            if (group.addUpdates.length || group.removeUpdates.length) {
                newChangesMade = true;
            }
        });

        this.setState({
            changesMade: newChangesMade
        });
    }

    /**
     * Sets the search field options to be users based on job codes
     * @param jobCodes list of job codes
     */
    async setSearchFieldOptions(jobCodes: string[]) {
        var query = { query: [{ column: 10, values: jobCodes }] }

        // we want to use the enterprise data if we have it otherwise default ot the legacy data
        const headers = (await DataService.getAllHeaders(process.env.REACT_APP_PRIMARY_FOF_ENVIRONMENT!)).data;

        const targetDS = !!headers.dataSources.find((x) => x.name === 'BOOMI_EMPLOYEES_ENTERPRISE') ? 'BOOMI_EMPLOYEES_ENTERPRISE' : 'BOOMI_EMPLOYEES';

        const [
            employeeIdsResponse, firstNamesResponse, lastNamesResponse,
        ] = await Promise.all([
            DataService.searchDataSourceDataWithQuery(targetDS, 0, query),
            DataService.searchDataSourceDataWithQuery(targetDS, 8, query),
            DataService.searchDataSourceDataWithQuery(targetDS, 9, query),
        ]);

        const employeeIds = employeeIdsResponse.data;
        const firstNames = firstNamesResponse.data;
        const lastNames = lastNamesResponse.data;

        // Format employee data received from query
        let employeeData = employeeIds.map((id: string, index: number) => ({
                employeeID: id,
                employeeName: firstNames[index] + " " + lastNames[index],
                employeeEmail: "N/A",
                manualAdd: true
            })
        );
        
        this.setState({
            searchFieldOptions: employeeData
        });
    }

    /**
     * Handles selecting a new project in the dropdown
     * @param project selected project's companyID
     */
    handleProjectChange = (project: string) => {
        if(this.props.updateProject) {
            this.props.updateProject(project);
        }
        this.setState({
            selectedProject: project,
            approversLoading: true,
            changesMade: false,

        });

        this.getFormApprovers(project);
    }

    /**
     * Handles selecting a new value in the search field
     * @param event selected value from search field
     */
    handleSearchFieldChange = async (event: any) => {
        // Reset userAlreadyAdded to false
        this.setState({ userAlreadyAdded: false });

        if (event.target.value) {
            var employeeIndex = this.state.searchFieldOptions.findIndex(x => x.employeeID === event.target.value);
            var employee = this.state.searchFieldOptions[employeeIndex];

            this.setState({
                userToManualAdd: employee
            });
            
            // Check if person is already in the group, set userAlreadyAdded to true if true
            var groupIndex = this.state.approverGroups.findIndex(x => x.groupName === this.state.manualAddGroupName);
            this.state.approverGroups[groupIndex].users.map((member) =>
                member.employeeID === employee.employeeID ? this.setState({ userAlreadyAdded: true }) : null);
        }
        else {
            this.setState({
                userToManualAdd: {
                    employeeID: "",
                    employeeName: "",
                    employeeEmail: "",
                    manualAdd: false
                }
            });
        }
    }

    /**
     * Opens the manual add modal
     * @param groupName name of group
     */
    onOpenManualAddModal = (groupName: string) => {
        this.setSearchFieldOptions(GROUP_TO_JOBCODE[groupName as keyof typeof GROUP_TO_JOBCODE]);
        
        this.setState({
            openModal: true,
            manualAddGroupName: groupName
        });
    }

    /**
     * Closes the modal
     */
    onCloseModal = () => {
        this.setState({
            openModal: false,
            manualAddGroupName: "",
            userAlreadyAdded: false,
            userToManualAdd: {
                employeeID: "",
                employeeName: "",
                employeeEmail: "",
                manualAdd: false
            }
        });
    }

    render() {
        const {
            projectLoading,
            selectedProject,
            projectList,
            approversLoading,
            approverGroups,
            openModal,
            manualAddGroupName,
            userAlreadyAdded,
            userToManualAdd,
            searchFieldOptions,
            canEdit,
            changesMade,
            saveLoading,
            saveStatus,
            showSaveStatus
        } = this.state;

        const FormApprovers = approversLoading ?
            <tr>
                <td colSpan={100}>
                    <Delayed>
                        <div className="DivPadded DivCenter"><Spinner animation="border" role="status" variant="primary" /></div>
                    </Delayed>
                </td>
            </tr> :
            approverGroups.length > 0 ?
                approverGroups.slice().sort((a, b) => a.groupName > b.groupName ? 1 : a.groupName < b.groupName ? -1 : 0).map((group) => 
                    <tr key={group.groupName}>
                        <td>
                            <CollapseField title={group.groupName} hintText={group.users.length + (group.users.length === 1 ? " group member" : " group members")} buttonStyle="collapse-button">
                                <div>
                                    <Table>
                                        <tbody>
                                            {group.users.map((user) => 
                                                <tr key={group.groupName + user.employeeID}>
                                                    <td style={{height: 55}}>
                                                        <div className='row align-middle' >
                                                            <div className='col-11 pt-2'>
                                                                {user.employeeName}<b>{user.manualAdd ? " *" : ""}</b>
                                                            </div>
                                                            <div className='pt-1 col-1 text-center'>
                                                                {canEdit && user.manualAdd ? <ButtonType dataName="removeMember" buttonText="x" color="red" size="sm" eventHandler={() => this.manualRemove(group.groupName, user)} /> : null}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                            {canEdit && group.groupEditable ? 
                                            <tr>
                                                <td style={{borderBottom: 'none'}} >
                                                    <Col style={{display:'flex', justifyContent:'right'}}>
                                                        <AddButton dataName="manualAdd" title="Manual Add" eventHandler={() => this.onOpenManualAddModal(group.groupName)} />
                                                    </Col>
                                                </td>
                                            </tr>
                                            : null}
                                        </tbody>
                                    </Table>
                                </div>
                            </CollapseField>
                        </td>
                    </tr>
                ) :
                <tr>
                    <td colSpan={100}>
                        <b>No Form Approver Groups Found</b>
                    </td>
                </tr>
            


        return(
            <div className='w-75'>
            <LoadingWrapper showLoading={projectLoading} loadingMessage="Getting Quality Form Approvers..." windSpinner windSpinnerScale={3}>
                <Modal show={openModal} onHide={this.onCloseModal} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>New {manualAddGroupName}:</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='h6'>
                            The PCC/PE or Quality Superintendent <b>must</b> call the trade General Superintendent to get verbal confirmation followed by an email that the person can be added to this group.
                        </div>
                        <div>
                            <SelectField 
                                dataName="memberSelect" 
                                title="Select an Employee" 
                                options={searchFieldOptions
                                    .slice().sort((a, b) => a.employeeName > b.employeeName ? 1 : -1)
                                    .map(({employeeName, employeeID}) => { return { label: employeeName, value: employeeID}})} 
                                value={userToManualAdd.employeeID}
                                onchange={this.handleSearchFieldChange}
                             />
                            <br></br>
                            {userAlreadyAdded ? 
                                <div>
                                    <b>This person is already in the group.</b>
                                    <br />
                                </div>
                            : 
                                <div>
                                    <small><i>Note: The manually added individual also needs to be added to the project in Blattner Mobile (if not already added). </i></small>
                                </div>
                            }
                            <br />
                            <div className="DivCenter">
                                <ButtonType
                                    eventHandler={() => this.manualAdd()}
                                    dataName="addMember"
                                    buttonText="Add"
                                    color="blue"
                                    readOnly={userToManualAdd.employeeID === "" || userAlreadyAdded}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <ProjectDropdown projectList={projectList} cookieName='projects' selectedProjectHandler={this.handleProjectChange} />

                <h1 className="header mt-5 mb-4 text-center">Quality Form Approver Groups</h1>

                {selectedProject ?
                    <div>
                        <div id="RightAlignedFloatDiv">
                        {canEdit ?
                            changesMade ?
                                <ButtonType dataName="save" color="blue" buttonText="Save" type="submit" eventHandler={() => this.saveUpdates()} />
                            : saveLoading ? 
                                <div className="DivPadded DivCenter"><Spinner animation="border" role="status" variant="primary" /></div>
                            : saveStatus === SAVESUCCESS ?
                                <div className="DivPadded DivCenter">
                                    <Fade in={showSaveStatus} >
                                        <b>Save Successful ✔</b>
                                    </Fade>
                                </div>
                            : saveStatus === SAVEERROR ?
                                <div className="DivPadded DivCenter">
                                    <b>An Error Occurred ⚠️</b><p><small><i>Please Refresh and Try Again</i></small></p>
                                </div>
                            : null
                        : null}
                        </div>
                        <div className="flex-grow-1 flex-wrap DivLeft">
                            <p>Process:</p>
                            <ol>
                                <li>Use the Add or Update User form to add someone to the Project's Blattner Mobile.</li>
                                <li>If they are not in the expected group (below) the next day, then work with the PCC/PE or Quality Superintendent.</li>
                                <ol type="a">
                                    <li>The PCC/PE or Quality Superintendent <b>must</b> call the trade General Superintendent to get verbal confirmation followed by an email that the person can be in the group.</li>
                                </ol>
                            </ol>
                            <p><i>Note: An (<b>*</b>) after a name indicates the PCC/PE or Quality Superintendent manually added the person to the group. Manually added individuals are put into their Blattner Mobile groups overnight.</i></p>
                        </div>
                        <Table>
                            <tbody>
                                {FormApprovers}
                            </tbody>
                        </Table>
                    </div>
                : null}
                <br />
                <br />
                <br />
                <br />
            </LoadingWrapper>
            </div>
        );
    }
}