import { useEffect, useState } from "react";


import DataSourceBackupList from "./DataSourceBackupList";
import LoadingWrapper from "../LoadingWrapper";
import DataSourceService from "../../services/DataSourceService"

import DataSourceBackup from "../../types/DataSource/DataSourceBackup";

interface DataSourceBackupDisplayProps {
    selectedProject: string,
    externalID: string,
};

const DataSourceBackupDisplay = ({ selectedProject, externalID }: DataSourceBackupDisplayProps) => {
    const [backupList, setBackupList] = useState<DataSourceBackup[]>();
    const [loadingBackups, setLoadingBackups] = useState<boolean>(true);

    useEffect(() => {
        if (backupList) {
            return;
        }
        setLoadingBackups(true);
        const getBackups = async () => {
            try {
                const { data } = await DataSourceService.getDataSourceBackups(selectedProject, externalID);
                setBackupList(data.sort((a, b) => b.date - a.date));
            } catch (error) {
                console.log(`Error getting the history for ${externalID} in ${selectedProject} with ${error}`);
            } finally {
                setLoadingBackups(false);
            }
        };
        getBackups();
    }, []);

    return (
        <div className={`d-flex justify-content-center`} style={{overflow: 'auto'}}>
            <LoadingWrapper showLoading={loadingBackups}>
                {backupList && <DataSourceBackupList backupList={backupList} />}
            </LoadingWrapper>
        </div>
    )
};

export default DataSourceBackupDisplay;