import http from "../http-common";
import DataSourceActivityDetail from "../types/DataSource/DataSourceActivityDetail";
import DataSourceBackup from "../types/DataSource/DataSourceBackup";
import DataSourceHistoryDetail from "../types/DataSource/DataSourceHistoryDetail";
import DataSourceRulesDetail from "../types/DataSource/DataSourceRulesDetail";

class DataSourceService {
  getDataSourceActivity(environmentID: string, externalID: string) {
    return http.get<DataSourceActivityDetail>(`/getDataSourceActivity?environmentID=${environmentID}&externalID=${externalID}`);
  }

  getDataSourceHistory(environmentID: string, externalID: string) {
    return http.get<DataSourceHistoryDetail[]>(`/getDataSourceHistory?environmentID=${environmentID}&externalID=${externalID}`);
  }

  getDataSourceRules(environmentID: string, externalID: string, autoGenerateOnFail: boolean = false) {
    return http.get<DataSourceRulesDetail>(`/getDataSourceRules?environmentID=${environmentID}&externalID=${externalID}&autoGenerateOnFail=${autoGenerateOnFail}`);
  }

  getAllDataSourceRules(externalID: string = '') {
    return http.get<DataSourceRulesDetail[]>(`/getAllDataSourceRules?externalID=${externalID}`);
  }

  getDataSourceBackups(environmentID: string, externalID: string) {
    return http.get<DataSourceBackup[]>(`/getDataSourceBackups?environmentID=${environmentID}&externalID=${externalID}`);
  }

  setDataSourceActivity(environmentID: string, externalID: string, updatedActivity: DataSourceActivityDetail) {
    return http.post(`/setDataSourceActivity?environmentID=${environmentID}&externalID=${externalID}`, updatedActivity, { headers: { 'Content-Type': 'application/json' }});
  }

  addDataSourceHistory(environmentID: string, externalID: string, historyToAdd: DataSourceHistoryDetail) {
    return http.post(`/addDataSourceHistory?environmentID=${environmentID}&externalID=${externalID}`, historyToAdd, { headers: { 'Content-Type': 'application/json' }});
  }

  setDataSourceRules(environmentID: string, externalID: string, ruleDetails: DataSourceRulesDetail) {
    return http.post(`/updateDataSourceRules?environmentID=${environmentID}&externalID=${externalID}`, ruleDetails, { headers: { 'Content-Type': 'application/json' }});
  }

  deleteDataSourceRule(environmentID: string, externalID: string, employeeEmail: string) {
    return http.delete(`/removeDataSourceRule?environmentID=${environmentID}&externalID=${externalID}&employeeEmail=${employeeEmail}`);
  }

  createDataSourceBackup(environmentID: string, externalID: string, employeeEmail: string) {
    return http.post(`/createDataSourceBackup?environmentID=${environmentID}&externalID=${externalID}&employeeEmail=${employeeEmail}`);
  }
}

const dataSourceServiceInstance = new DataSourceService();

export default dataSourceServiceInstance;