export enum BlattnerPortalPage {
    FormSubmissions = 1,
    NCRTasks = 2,
    DOTCoordintators = 3,
    QualityFormApprovers = 4,
    ProjectGoals = 5,
    DataSourceEditor = 6,
    RulesEditor = 7,
    Admin = 8,
    DataSourceHistory = 9,
};

export const BlattnerPortalPageLabel: { [key in BlattnerPortalPage]: string } = {
    [BlattnerPortalPage.FormSubmissions]: 'Form Submissions',
    [BlattnerPortalPage.NCRTasks]: 'NCR Tasks',
    [BlattnerPortalPage.DOTCoordintators]: 'DOT Coordinators',
    [BlattnerPortalPage.QualityFormApprovers]: 'Quality Form Approvers',
    [BlattnerPortalPage.ProjectGoals]: 'Project Goals',
    [BlattnerPortalPage.DataSourceEditor]: 'Data Source Editor',
    [BlattnerPortalPage.RulesEditor]: 'Rules Editor',
    [BlattnerPortalPage.Admin]: 'Admin',
    [BlattnerPortalPage.DataSourceHistory]: 'Data Source History',
};